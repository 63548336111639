import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { Card } from 'primereact/card';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge'
import { ThemeContext } from '../../context/ContextHeader';
import { useRef } from 'react';

function Corbeille() {
    const location = useLocation();
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [ingredients, setIngredients] = useState([]);
    const [sites, setSites] = useState([]);
    const useRefInput = useRef(null);
    const {
        items, setItems,
        nbFoldersSend, setNbFoldersSend,
        nbFoldersRemove, setNbFoldersRemove,
        disable, setDisable,
        disableAvis, setDisableAvis,
        dossiersCorbeille, setDossiersCorbeille,
        dossiersSousCorbeille, setDossiersSousCorbeille
    } = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 25;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = dossiersCorbeille.slice(firstIndex, lastIndex);
    const npage = Math.ceil(dossiersCorbeille.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [checked, setChecked] = useState(false);
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }


    let URL = "http://127.0.0.1:8000/sites/";
    const onIngredientsChange = (e, statut) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);

        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
        setItems(_ingredients);
        if (_ingredients.length > 0) {



            setDisable(false)
        } else {
            //setDisable(true)
            setDisable(true)
        }

    }



    const liste_dossiers_by_siteEnteteAccordion = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
            if (response.data.status === 200) {
                setDossiersCorbeille(response.data.dossier_send);
                //setDossiersSousCorbeille(response.data.data_dossier_sub);
                // setNbFoldersRemove(response.data.data_dossier_num.length)
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const Title = (societe, id) => {
        return (
            <div style={{ display: 'flex', alignItems: 'start' }}>
                <h5 style={{ color: '#18d26e' }}><i className='fa fa-user '>&nbsp;</i>{societe}</h5>

            </div>
        )
    }

    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';

        link.href = value;

        link.click();
    };

    const handleOpen = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = value;
        link.target = "_blank";

        link.click();
    };
    useEffect(() => {
        // liste_dossiers_by_site(14,)
        setDisable(true)
        liste_dossiers_by_siteEnteteAccordion(membre_id);
        setDisableAvis(true)
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">

                    <div className='container-fluid mt'>
                        {dossiersCorbeille.length > 0 && <div class="row ">

                            <table class="table table-bordered table-striped table-condensed">
                                <thead>
                                    <tr style={{ backgroundColor: 'white' }}>
                                        <th style={{ color: 'red' }}><center></center></th>
                                        <th style={{ color: 'red' }}>Sites</th>
                                        <th style={{ color: 'red' }}>Patient(s)</th>
                                        <th style={{ color: 'red', width: '20%' }}>Examen(s)</th>
                                        <th style={{ color: 'red' }}>Catégorie(s)</th>
                                        <th style={{ color: 'red' }}>Date</th>
                                        <th style={{ color: 'red' }}></th>
                                        <th style={{ color: 'red' }}></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {records
                                        .map((dossierRecu, index) => (
                                            <tr key={dossierRecu.id}>
                                                <td><center>
                                                    {dossierRecu.etat == 1 &&
                                                        <img
                                                            src="../../../assets/img/urgent.png"
                                                            width="50"
                                                            style={{ marginTop: 5 }}
                                                        />}
                                                </center></td>
                                                <td>{dossierRecu.societe}</td>
                                                <td>{dossierRecu.nom_pat}</td>
                                                <td>
                                                    <div style={{ display: 'flex' }}>

                                                        {dossierRecu.nom_fichier !== null && <h6><Link style={{ color: 'green' }}
                                                            to={'/resultat'}
                                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier)}
                                                        >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </h6>}{dossierRecu.nom_fichier_2 !== null && <h6>&nbsp;<Link style={{ color: 'green' }}
                                                            to={'/resultat'}
                                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier_2)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></h6>}
                                                        {dossierRecu.bulletin_examen !== null && <h6><Link to='/resultat' style={{ position: 'absolute', color: 'green' }} onClick={(e) => handleOpen(e, URL_FOLDER_API + dossierRecu.chemin_bulletin)}>Bulletin</Link></h6>}
                                                    </div>
                                                </td>
                                                <td>{dossierRecu.categorie_fichier}</td>
                                                <td>{dossierRecu.created_at}</td>
                                                <td><center>
                                                    <Checkbox inputId={`dossier_${dossierRecu.id}`} name={dossierRecu.id} value={dossierRecu.id} onChange={(e) => onIngredientsChange(e, dossierRecu.statut)} checked={ingredients.includes(dossierRecu.id)} />
                                                </center></td>
                                                <td>
                                                    <i className='fa fa-hand-o-up'
                                                        onClick={() => navigate(`/member_detail_folder/${dossierRecu.id}`)}
                                                        style={{ color: 'red', cursor: 'pointer' }}>
                                                    </i>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </table>

                            <nav>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={prevPage}
                                        >Prev</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href='#' className='page-link'
                                                    onClick={() => changeCPage(n)}
                                                >{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={nextPage}
                                        >Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>}

                        {dossiersCorbeille.length <= 0 && <div style={{ display: 'flex' }}><img src='../../img/folder_empty.png' /> <h3 style={{ color: 'red', marginTop: '110px' }}>Aucun dossier trouvé</h3></div>}
                    </div>
                    {/* <div class="col-lg-2 col-md-2 col-sm-2 mb">
                <div class="weather-2 pn">
                    <div class="weather-2-header">
                        <div class="row">
                            <div class="col-sm-6 col-xs-6">
                                <p>{props.patient}</p>
                            </div>
                            <div class="col-sm-6 col-xs-6 goright">
                                <p class="small">{props.date}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row centered">
                        <img src="../../../assets/img/cr.png" class="" width="120" />
                    </div>
                    <div class="row data">
                        <div class="col-sm-6 col-xs-6 goleft">
                            <h6><b>{props.titre}</b></h6>
                            <h6><b>Dr {props.medecin}</b></h6>
                           
                        </div>
                        <div class="col-sm-6 col-xs-6 goright">
                        <img src="../../../assets/img/urgent.png" class="" width="80" />
                           
                        </div>
                    </div>
                </div>
            </div>*/}

                    <br /><br /><br /><br />
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default Corbeille;