import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Chart } from 'primereact/chart';
import { URL_AXIOS, URL_FOLDER_API, URL_MAIN } from '../../URL/URL';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import JoditEditor from 'jodit-react';
import { Panel } from 'primereact/panel';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from 'primereact/floatlabel';
function Statistiques() {
  const editor = useRef(null);
  const {
    disableAvis, setDisableAvis,
    dataExamenStatistique, setDataExamenStatistique,
    periodeContext, setPeriodeContext,
    examen_total, setExamen_total
  } = useContext(ThemeContext);
  let totaleachRowGet = 0;
  let currentDate = new Date();
  const usernameconnected = sessionStorage.getItem("nom");
  const user_id = sessionStorage.getItem("user_id");
  const membre_id = parseInt(sessionStorage.getItem("membre_id"));
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [donnee, setDonnee] = useState([]);
  const [periode, setPeriode] = useState("");
  const [sites, setSites] = useState([]);
  const [root_stat_site, setRoot_stat_site] = useState([]);
  const [labels, setLabels] = useState([]);
  const [eachSiteData, setEachSiteData] = useState([]);
  const [eachSiteMontsData, setEachMontsSiteData] = useState([]);
  let totalJAnvier = 0;
  let totalFevrier = 0;
  let totalMars = 0;
  let totalAvril = 0;
  let totalMai = 0;
  let totalJuin = 0;
  let totalJuillet = 0;
  let totalAout = 0;
  let totalSeptembre = 0;
  let totalOctobre = 0;
  let totalNovembre = 0;
  let totalDecembre = 0;
  const [totaleachRow, setTotaleachRow] = useState([]);
  const [totaleachRowByMonth, setTotaleachRowByMonth] = useState([]);
  const [eachSiteDataSubTotal, setEachSiteDataSubTotal] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const [societe, setSociete] = useState("");
  const [societeM, setSocieteM] = useState("");
  const [modalite, setModalite] = useState('');
  const [modaliteM, setModaliteM] = useState('');
  const [sexe, setSexe] = useState('');
  const [sexeM, setSexeM] = useState('');
  const [motcle, setMotcle] = useState("");
  const [motcleM, setMotcleM] = useState("");
  const [age, setAge] = useState();
  const [ageF, setAgeF] = useState();
  const [ageM, setAgeM] = useState();
  const [ageFM, setAgeFM] = useState();
  const [boolBtn, setBoolBtn] = useState(true);
  const handleInputAge = (event) => {
    const value = event.target.value;
    const regex = /^-?\d*$/;

    if (value !== "") {
      setAge(value)
    } else {
      setAge("")
    }
    if (regex.test(value)) {
      setAge(value)
    } else {
      setAge("")
    }
  };

  const handleInputAgeF = (event) => {
    const value = event.target.value;
    const regex = /^-?\d*$/;

    if (value !== "") {
      setAgeF(value)
    } else {
      setAgeF("")
    }
    if (regex.test(value)) {
      setAgeF(value)
    } else {
      setAgeF("")
    }
  };
  const years = [
    {
      label: "2023",
      value: "2023"
    },
    {
      label: "2024",
      value: "2024"
    },
    {
      label: "2025",
      value: "2025"
    },
    {
      label: "2026",
      value: "2026"
    },
    {
      label: "2027",
      value: "2027"
    },
    {
      label: "2028",
      value: "2028"
    },
    {
      label: "2029",
      value: "2029"
    },
    {
      label: "2030",
      value: "2030"
    }
  ];

  const MonthDataCompar = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"];
  const op = useRef(null);


  const cancelReseach = () => {
    setBoolBtn(true)
    setSociete('');
    setSocieteM('');
    setAge('');
    setAgeF('');
    setAgeFM('');
    setAgeM('');
    setModalite('');
    setModaliteM('');
    setSexe('');
    setSexeM('');
    setMotcle('');
    setMotcleM('');
    load_data_exams_stat(membre_id, periode);

  }
  const load_data_exams_stat = async (membre, periode) => {
    try {

      const response = await axios.get(URL_AXIOS + `load_data_exams/${membre}/${periode}`);
      if (response.data.status == 200) {
        setDataExamenStatistique(response.data.statistiqueAllData);
        setExamen_total(response.data.sommeTotalForeAllSite)

      } else {

      }


    } catch (error) {

    }
  }
  const rechercher_advanced = async (id, p, societ, sex, ag, categorieExamen, obs) => {
    try {

      const data = {
        societe: societe != "" ? societe : "NA",
        sexe: sexe != "" ? sexe : "NA",
        categorieExamen: modalite != "" ? modalite : "NA",
        age: age != "" ? age : "NA",
        observation: motcle != "" ? motcle : "NA",
        age2: ageF != "" ? ageF : "NA",
      }

      setAgeFM(ageF);
      setAgeM(age);
      setModaliteM(modalite);
      setSocieteM(societe);
      setSexeM(sexe);
      setMotcleM(motcle);
      const response = await axios.get(URL_AXIOS + `recherche_in_vue_autres_statistiqueAdvanced/${id}/${p}/${data.societe}/${data.sexe}/${data.age}/${data.categorieExamen}/${data.observation}/${data.age2}`);

      if (response.data.status === 200) {

        setDataExamenStatistique(response.data.statistiqueAllData);
        setExamen_total(response.data.sommeTotalForeAllSite)
        setBoolBtn(false)


      } else {
        load_data_exams_stat(membre_id, periode);
      }
    } catch (error) {

    }
  }

  const LoadAllSiteByMember = async (id, p) => {
    try {
      const response = await axios.get(URL_AXIOS + `allSiteByMemeber/${id}/${p}`);
      if (response.data.status === 200) {
        setSites(response.data.all_site);
        setEachSiteData(response.data.examenBySite);
        setEachSiteDataSubTotal(response.data.marsArray);
        setEachMontsSiteData(response.data.data_stat_months);
        setTotaleachRow(response.data.data_stat_total);
        setDataExamenStatistique(response.data.statistiqueAllData);
        setTotaleachRowByMonth(response.data.data_stat_total_for_each_month);
        setExamen_total(response.data.total_examens_for_all_site);

      } else {

      }
    } catch (error) {

    }
  }
  const onChangePeriode = async (e) => {

    try {
      const value = e.target.value;
      setPeriode(value);
      setPeriodeContext(value);
      const data = {
        membre_id: parseInt(membre_id),
        periode: value
      }
      if (value !== "") {

        const response = await axios.post(URL_AXIOS + "create_statistique", data);
        if (response.data.status === 200) {
          setRoot_stat_site(response.data.root_stat_site);
          const MonthOfYearLabels = [
            (response.data.jan) ? response.data.jan.mois_lettre : 'Jan',
            (response.data.fev) ? response.data.fev.mois_lettre : 'Fev',
            (response.data.mar) ? response.data.mar.mois_lettre : 'Mar',
            (response.data.avr) ? response.data.avr.mois_lettre : 'Avr',
            (response.data.mai) ? response.data.mai.mois_lettre : 'Mai',
            (response.data.jun) ? response.data.jun.mois_lettre : 'Jun',
            (response.data.jul) ? response.data.jul.mois_lettre : 'Jul',
            (response.data.aou) ? response.data.aou.mois_lettre : 'Aou',
            (response.data.sep) ? response.data.sep.mois_lettre : 'Sep',
            (response.data.oct) ? response.data.oct.mois_lettre : 'Oct',
            (response.data.nov) ? response.data.nov.mois_lettre : 'Nov',
            (response.data.dec) ? response.data.dec.mois_lettre : 'Dec'
          ]



          if (MonthOfYearLabels.length > 0) {
            setLabels(MonthOfYearLabels)
          } else {

            setLabels(["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"])
          }

          const DataMonthOfYear = [
            (response.data.jan) ? response.data.jan.netapayer : 0,
            (response.data.fev) ? response.data.fev.netapayer : 0,
            (response.data.mar) ? response.data.mar.netapayer : 0,
            (response.data.avr) ? response.data.avr.netapayer : 0,
            (response.data.mai) ? response.data.mai.netapayer : 0,
            (response.data.jun) ? response.data.jun.netapayer : 0,
            (response.data.jul) ? response.data.jul.netapayer : 0,
            (response.data.aou) ? response.data.aou.netapayer : 0,
            (response.data.sep) ? response.data.sep.netapayer : 0,
            (response.data.oct) ? response.data.oct.netapayer : 0,
            (response.data.nov) ? response.data.nov.netapayer : 0,
            (response.data.dec) ? response.data.dec.netapayer : 0
          ]
          let sommeData = DataMonthOfYear.reduce((acc, valeur) => acc + valeur, 0);

          if (sommeData > 0) {
            setDonnee(DataMonthOfYear)
          } else {
            setDonnee([])
          }

        } else {

        }
      } else {
        setPeriodeContext("");
      }
    } catch (error) {

    }

  }

  const handleDownload = (e, value) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.download = 'Example-PDF-File';

    link.href = value;

    link.click();
  };

  const handleOpen = (e, value) => {
    e.preventDefault();
    const link = document.createElement('a');
    //link.download = 'Example-PDF-File';

    link.href = value;
    link.target = "_blank";

    link.click();
  };
  const chartLine = () => {
    try {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
      const data = {
        labels: labels,
        datasets: [
          {
            type: 'line',
            label: periode,
            color: "white",
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: "#fff",
            borderWidth: 2,
            fill: true,
            tension: 0.4,
            data: donnee
          }

        ]
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.9,
        plugins: {
          legend: {
            labels: {
              color: "#fff",
              font: {
                weight: 'bold'
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: "#fff",
              font: {
                weight: 'bold', // set the font weight here
              },
            },

          },
          y: {
            ticks: {
              color: "#fff",
              font: {
                weight: 'bold', // set the font weight here
              },
            },

          }
        }
      };

      setChartData(data);
      setChartOptions(options);
    } catch (error) {

    }
  }

  const headerAccordionData = (societe, number) => {
    return (
      <div style={{ display: 'flex', width: "100%" }}>
        <p>{societe}</p>
        <p style={{ marginLeft: "15px", width: "20px", height: "20px", display: 'none', backgroundColor: 'red', borderRadius: "100%", padding: "5px", color: 'white' }}>{number}</p>
      </div>
    )
  }
  const config = {

    height: 'auto',
    allowResizeX: false,
    allowResizeY: false,
    autofocus: true,
    toolbarAdaptive: false,
    editorClassName: 'editorComponent_apercu2',
    readonly: true,
    toolbar: true,
    //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: true,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    statusbar: false,
    width: '100%',
    zIndex: 0,
    toolbarSticky: false,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    i18n: 'fr',
    tabIndex: -1,
    enter: "P",
    useSplitMode: false,
    colors: {
      greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
      palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
      full: [
        '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
        '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
        '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
        '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
        '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
        '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
      ]
    },
    colorPickerDefaultTab: 'background',
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    buttons: [

    ],
    /*buttonsXS: [
        'bold',
        'image', '|',
        'paragraph', '|',
        'align', '|',
        'undo', 'redo', '|',
        'eraser',
        'dots'
    ],*/
    events: {},
    textIcons: false,
  }
  useEffect(() => {
    LoadAllSiteByMember(membre_id, periode);
    setPeriodeContext(periode)
    chartLine();
    if (usernameconnected === null || usernameconnected === "") {
      navigate('/')
    }
  }, [donnee,periode]);
  useEffect(() => {
    setDisableAvis(true)
  }, [periode])

  const headerMainFirst = () => {
    return (
      <div>
        <h5 style={{ color: 'red' }}>Nombre des examens interpétés par centre</h5>
      </div>
    )
  }
  const headerMainSecond = (numberExamens) => {
    return (
      <div>
        <h5 style={{ color: 'red', textTransform: 'uppercase' }}>Résultat ({numberExamens}) CR {societeM && ("- " + societeM)} {modaliteM && ("- " + modaliteM)} {sexeM && ("- de sexe " + (sexeM == "M" ? "masculin" : 'féminin'))} {ageF && ("agés de " + ageM + " à " + ageFM + " - ")}   {motcleM && ("- mot cle ") + "(" + motcleM + ")"}   </h5>
      </div>
    )
  }
  const headerPatient = (Patient, Examen, Centre, Fichier, Fichier_2, Bulletin, date, age, sexe) => {
    return (
      <div className='row' style={{ marginLeft: 1, marginTop: "-10px", position: 'absolute', width: "100%" }}>
        <table className='table'>
          <tr style={{ color: 'black' }}>
            <th style={{ width: "28%" }}>{Patient}</th>
            <th style={{ width: "26%" }}>{Centre}</th>
            <th style={{ width: "8%" }}>{age}</th>
            <th style={{ width: "5%" }}>{sexe}</th>
            <th style={{ width: "22%" }}>{Examen}</th>
            <th> {format(date, 'dd/MM/yyyy')}</th>
          </tr>
        </table>


      </div>

    )
  }
  return (
    <div style={{ overflow: 'none' }}>
      <Header />
      <AsideBar username={usernameconnected} />
      <section id="main-content">
        <section class="wrapper">
          <div className='row'>
            <div className='card' style={{ width: "100%", height: "12vh" }}>
              <div className='row'>
                <div className='col col-md-3'>

                </div>
                <div className='col col-md-6'>
                  <div style={{ display: "flex" }}>
                    <label style={{ marginTop: 30, fontWeight: 600, color: 'black' }}>Période *:</label>
                    <select className='form-control' value={periode}
                      onChange={(e) => onChangePeriode(e)}
                      style={{ marginTop: 20, width: "80%", marginLeft: 10, fontWeight: 600, color: 'black' }}>
                      <option value="">Veuillez sélectionner la période</option>
                      {years.map((y, index) => (
                        <option key={index} value={y.value}>{y.label}</option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className='col col-md-3'>


                </div>
              </div>

            </div>

          </div>
          <div className='row'>
            <div className="card" style={{ height: "auto", width: "98%", marginLeft: "1%", backgroundColor: "#163356" }}>
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </div>
          <div className='row'>
            <div className="content" style={{ marginTop: "1%", width: "98%", marginLeft: "1%" }}>
              <form id="form{{ 0 }}" action="{{route('members.statistique')}}" method="GET">
                <div class="row">

                  {
                    sites.map((site, index) => (


                      <div class="col-md-6" key={index}>
                        <div className="card card-chart">
                          <div class="card-header" style={{ padding: 10 }}>
                            <h5 class="card-category">{site.type}</h5>
                            <p class="card-title" style={{ color: 'red', fontWeight: 'bold' }}>{site.societe}</p>
                            <div class="dropdown">
                              <button type="button" class="btn btn-round btn-outline-default dropdown-toggle btn-simple btn-icon no-caret"
                                style={{ float: 'right' }}
                                onClick={(e) => op.current.toggle(e)}>
                                <i class="fa fa-gear"></i>
                              </button>
                              <OverlayPanel ref={op} showCloseIcon closeOnEscape dismissable={false}>
                                <div className='container-fluid'>
                                  <div className='row'>
                                    <button type='button' className='btn btn-primary form-control'>Charger les données</button>
                                  </div>
                                  <br />
                                  <div className='row'>
                                    <button type="button" className="btn btn-info form-control">Liste des prix</button>
                                    <input type="hidden" name="_{{$site->site_id}}" value="{{$site->site_id}}" />
                                  </div>


                                </div>
                              </OverlayPanel>

                            </div>
                          </div>
                          <div class="card-body" style={{ padding: 10 }}>
                            <div class="table-responsive-md">
                              <table class="table">
                                <thead class=" text-primary">
                                  <th>
                                    Période
                                  </th>
                                  <th>
                                    Exam.
                                  </th>
                                  <th>
                                    C.A.
                                  </th>
                                  <th>
                                    %
                                  </th>
                                  <th>
                                    Net
                                  </th>
                                  <th class="text-right"></th>
                                </thead>
                                <tbody>
                                  {

                                    root_stat_site[site.site_id]?.map((ss, index) => (


                                      <tr key={index}>
                                        <td>
                                          {ss.periode}
                                        </td>
                                        <td>
                                          {ss.nbrExamen}
                                        </td>
                                        <td>

                                          {ss.gain !== 0 ? new Intl.NumberFormat().format(ss.gain) + "  XAF" : 0}
                                        </td>
                                        <td>
                                          {ss.pourcentage}
                                        </td>
                                        <td>
                                          { /* {{number_format($ss->netapayer,0,',',' ')}}{{__(' XAF')}}*/}
                                          {ss.netapayer !== 0 ? new Intl.NumberFormat().format(ss.netapayer) + "  XAF" : 0}
                                        </td>
                                        <td class="text-right">
                                          <i class="fa fa-gear" style={{ fontSize: 20, border: "1px solid grey", padding: 10, borderRadius: "100%", cursor: "pointer" }}></i>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div class="col-md-12">
                    <div class="card  card-tasks" style={{ width: '100%', height: 'auto' }}>
                      <div class="card-header " style={{ padding: 10 }}>
                        <h3 class="card-category" style={{ textAlign: 'center' }}>Autres statistiques</h3>

                      </div>
                      <div class="card-body " style={{ padding: 10 }}>
                        <Accordion activeIndex={1}>
                          <AccordionTab header={headerMainFirst}>
                            {

                              sites?.map((site, index) => (
                                <Accordion >
                                  <AccordionTab header={headerAccordionData(site.societe, eachSiteData[site.site_id]?.length)}>

                                    <table className='table table-bordered table-striped table-condensed' key={index}>
                                      <thead>
                                        <tr >

                                          <th>TITRE</th>
                                          <th style={{ textAlign: 'center' }}>Jan</th>
                                          <th style={{ textAlign: 'center' }}>Fevr</th>
                                          <th style={{ textAlign: 'center' }}>Mars</th>
                                          <th style={{ textAlign: 'center' }}>Avr</th>
                                          <th style={{ textAlign: 'center' }}>Mai</th>
                                          <th style={{ textAlign: 'center' }}>Juin</th>
                                          <th style={{ textAlign: 'center' }}>Juil</th>
                                          <th style={{ textAlign: 'center' }}>Août</th>
                                          <th style={{ textAlign: 'center' }}>Sept</th>
                                          <th style={{ textAlign: 'center' }}>Oct</th>
                                          <th style={{ textAlign: 'center' }}>Nov</th>
                                          <th style={{ textAlign: 'center' }}>Dec</th>

                                          <th style={{ textAlign: 'center' }}>TOTAL</th>
                                        </tr>
                                      </thead>

                                      <tbody>

                                        {

                                          eachSiteData[site.site_id]?.map((val, index2) => {
                                            var Janvier = (eachSiteMontsData[site.site_id][val.titre]["01"] != null ? eachSiteMontsData[site.site_id][val.titre]["01"] : 0);
                                            var Fevrier = (eachSiteMontsData[site.site_id][val.titre]["02"] != null ? eachSiteMontsData[site.site_id][val.titre]["02"] : 0);
                                            var Mars = (eachSiteMontsData[site.site_id][val.titre]["03"] != null ? eachSiteMontsData[site.site_id][val.titre]["03"] : 0);
                                            var Avril = (eachSiteMontsData[site.site_id][val.titre]["04"] != null ? eachSiteMontsData[site.site_id][val.titre]["04"] : 0);
                                            var Mai = (eachSiteMontsData[site.site_id][val.titre]["05"] != null ? eachSiteMontsData[site.site_id][val.titre]["05"] : 0);
                                            var Juin = (eachSiteMontsData[site.site_id][val.titre]["06"] != null ? eachSiteMontsData[site.site_id][val.titre]["06"] : 0);
                                            var Juillet = (eachSiteMontsData[site.site_id][val.titre]["07"] != null ? eachSiteMontsData[site.site_id][val.titre]["07"] : 0);
                                            var Aout = (eachSiteMontsData[site.site_id][val.titre]["08"] != null ? eachSiteMontsData[site.site_id][val.titre]["08"] : 0);
                                            var Septembre = (eachSiteMontsData[site.site_id][val.titre]["09"] != null ? eachSiteMontsData[site.site_id][val.titre]["09"] : 0);
                                            var Octobre = (eachSiteMontsData[site.site_id][val.titre]["10"] != null ? eachSiteMontsData[site.site_id][val.titre]["10"] : 0);
                                            var Novembre = (eachSiteMontsData[site.site_id][val.titre]["11"] != null ? eachSiteMontsData[site.site_id][val.titre]["11"] : 0);
                                            var Decembre = (eachSiteMontsData[site.site_id][val.titre]["12"] != null ? eachSiteMontsData[site.site_id][val.titre]["12"] : 0);
                                            const rowTotal = (parseInt(Janvier) + parseInt(Fevrier) + parseInt(Mars) + parseInt(Avril) + parseInt(Mai) + parseInt(Juin) + parseInt(Juillet) + parseInt(Aout) + parseInt(Septembre) + parseInt(Octobre) + parseInt(Novembre) + parseInt(Decembre));
                                            totalJAnvier = parseInt((totaleachRowByMonth[site.site_id][val.titre]["01"] != null ? totaleachRowByMonth[site.site_id][val.titre]["01"] : 0));
                                            totalFevrier = parseInt((totaleachRowByMonth[site.site_id][val.titre]["02"] != null ? totaleachRowByMonth[site.site_id][val.titre]["02"] : 0));
                                            totalMars = parseInt((totaleachRowByMonth[site.site_id][val.titre]["03"] != null ? totaleachRowByMonth[site.site_id][val.titre]["03"] : 0));
                                            totalAvril = parseInt((totaleachRowByMonth[site.site_id][val.titre]["04"] != null ? totaleachRowByMonth[site.site_id][val.titre]["04"] : 0));
                                            totalMai = parseInt((totaleachRowByMonth[site.site_id][val.titre]["05"] != null ? totaleachRowByMonth[site.site_id][val.titre]["05"] : 0));
                                            totalJuin = parseInt((totaleachRowByMonth[site.site_id][val.titre]["06"] != null ? totaleachRowByMonth[site.site_id][val.titre]["06"] : 0));
                                            totalJuillet = parseInt((totaleachRowByMonth[site.site_id][val.titre]["07"] != null ? totaleachRowByMonth[site.site_id][val.titre]["07"] : 0));
                                            totalAout = parseInt((totaleachRowByMonth[site.site_id][val.titre]["08"] != null ? totaleachRowByMonth[site.site_id][val.titre]["08"] : 0));
                                            totalSeptembre = parseInt((totaleachRowByMonth[site.site_id][val.titre]["09"] != null ? totaleachRowByMonth[site.site_id][val.titre]["09"] : 0));
                                            totalOctobre = parseInt((totaleachRowByMonth[site.site_id][val.titre]["10"] != null ? totaleachRowByMonth[site.site_id][val.titre]["10"] : 0));
                                            totalNovembre = parseInt((totaleachRowByMonth[site.site_id][val.titre]["11"] != null ? totaleachRowByMonth[site.site_id][val.titre]["11"] : 0));
                                            totalDecembre = parseInt((totaleachRowByMonth[site.site_id][val.titre]["12"] != null ? totaleachRowByMonth[site.site_id][val.titre]["12"] : 0));
                                            totaleachRowGet = (totaleachRow[site.site_id] != null ? totaleachRow[site.site_id] : 0);
                                            return (
                                              <tr style={{ textAlign: 'center' }} key={index2}>

                                                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{val.titre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Janvier}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Fevrier}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Mars}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Avril}
                                                </td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Mai}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Juin}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Juillet}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Aout}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Septembre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Octobre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Novembre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Decembre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{rowTotal}</td>
                                              </tr>

                                            )
                                          })}

                                      </tbody>
                                      <tfoot>
                                        <tr >
                                          <td>

                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalJAnvier}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalFevrier}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalMars}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalAvril}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalMai}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalJuin}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalJuillet}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalAout}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalSeptembre}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalOctobre}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalNovembre}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {totalDecembre}
                                          </td>

                                          <td style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>{totaleachRowGet}</td>


                                        </tr>
                                      </tfoot>



                                    </table>

                                  </AccordionTab>

                                </Accordion>


                              ))
                            }
                          </AccordionTab>
                        </Accordion>
                        <Panel header="Recherche avancée">
                          <div className='row' style={{ marginLeft: 10 }}>
                            <div className='col col-md-3'>
                              <select className='form-control' style={{ fontWeight: 'bold', fontSize: 12 }}
                                value={societe} onChange={(e) => setSociete(e.target.value)}>
                                <option value={""}>veuillez sélectionner le site</option>
                                {sites.map((si, index) => (
                                  <option style={{ fontWeight: 'bold', fontSize: 12 }} key={index} value={si.societe}>{si.societe}</option>
                                ))}
                              </select>
                            </div>
                            <div className='col col-md-2'>
                              <select className='form-control' style={{ fontSize: 12, fontWeight: 'bold' }}
                                value={modalite} onChange={(e) => setModalite(e.target.value)}
                              >
                                <option value={""}>veuillez sélectionner la modalité</option>
                                <option style={{ fontWeight: 'bold', fontSize: 12 }} value={"SCANNER"}>SCANNER</option>
                                <option style={{ fontWeight: 'bold', fontSize: 12 }} value={"IRM"}>IRM</option>
                                <option style={{ fontWeight: 'bold', fontSize: 12 }} value={"RADIOGRAPHIE"}>RADIOGRAPHIE</option>
                                <option style={{ fontWeight: 'bold', fontSize: 12 }} value={"ECHOGRAPHIE"}>ECHOGRAPHIE</option>
                              </select>
                            </div>

                            <div className='col col-md-7'>
                              <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 10 }}>
                                <label style={{ marginTop: 10, fontSize: 12 }}>Age:</label>
                                <FloatLabel>
                                  <InputText id="Age" value={age} onChange={handleInputAge} style={{ width: 50, fontSize: 12, marginLeft: 10 }} />
                                  <label for="Age" style={{ fontSize: 14, marginLeft: 10 }}>De</label>
                                </FloatLabel>
                                <FloatLabel>
                                  <InputText id="Age" value={ageF} onChange={handleInputAgeF} style={{ width: 50, fontSize: 12, marginLeft: 10 }} />
                                  <label for="Age" style={{ fontSize: 14, marginLeft: 10 }}>A</label>
                                </FloatLabel>
                                <select className='form-control' style={{ width: 80, marginLeft: 10 }}
                                  value={sexe} onChange={(e) => setSexe(e.target.value)}
                                >
                                  <option value={""}>Sexe</option>
                                  <option value={'M'}>M</option>
                                  <option value={'F'}>F</option>
                                </select>
                                <FloatLabel>
                                  <InputText id="word" value={motcle} onChange={(e) => setMotcle(e.target.value)} style={{ width: "270px", fontSize: 12, marginLeft: 10 }} />
                                  <label for="word" style={{ fontSize: 14, marginLeft: 10 }}>Mot clé</label>
                                </FloatLabel>
                                <button type='button' style={{ marginLeft: 10 }} className={boolBtn == true ? 'btn btn-info' : 'btn btn-danger'}
                                  onClick={boolBtn == true ? () => rechercher_advanced(membre_id, periode, societe, sexe) : () => cancelReseach()}
                                >
                                  <i className={boolBtn == true ? 'fa fa-search' : 'fa fa-remove'}></i>
                                </button>
                              </div>
                            </div>

                          </div>
                        </Panel>
                        <Accordion activeIndex={0} toggle={true} style={{ marginTop: 5 }}>
                          <AccordionTab header={headerMainSecond(examen_total)}>
                            <Accordion>
                              {
                                dataExamenStatistique?.map((v, index) => (
                                  <AccordionTab header={headerPatient(v.nom_patient, v.titre, v.societe, v.chemin_fichier, v.chemin_fichier_2, v.chemin_bulletin, v.created_at, (v.age + "  " + v.libelle_age), v.sexe)}>
                                    <table className='table'>

                                      <tr>
                                        <h3 className='text-center' style={{ marginTop: "1%", color: 'black', textDecoration: "underline" }}>{v.categorie_fichier}</h3>
                                      </tr>
                                      <tr>
                                        <div style={{ display: 'flex', marginTop: "10px", color: 'green', marginLeft: '70%' }}>

                                          {v.chemin_fichier !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={v.chemin_fichier}
                                            to={'/statistiques'}
                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + v.chemin_fichier)}
                                          >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </p>}

                                          {v.chemin_fichier_2 !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}>&nbsp;<Link
                                            title={v.chemin_fichier_2}
                                            to={'/statistiques'}
                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + v.chemin_fichier_2)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></p>}


                                          {v.chemin_bulletin !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={v.chemin_bulletin} style={{ position: 'absolute' }} to={'/statistiques'} onClick={(e) => handleOpen(e, URL_FOLDER_API + v.chemin_bulletin)}>Bulletin</Link></p>}
                                        </div>
                                      </tr>
                                    </table>


                                    <table style={{ width: "100%" }}>
                                      <tr style={{ width: "100%" }}>
                                        <JoditEditor
                                          config={config}
                                          ref={editor}
                                          value={v.observation}
                                          tabIndex={1}
                                        />
                                      </tr>
                                    </table>
                                    <table style={{ marginTop: 10, width: "100%" }}>
                                      <tr>
                                        <th>
                                          {v.img_obs_1 && <img src={URL_MAIN + v.img_obs_1} style={{ width: '150px', height: '150px', float: 'left' }} />}
                                          {v.img_obs_1 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.1</h6>}
                                        </th>
                                        <th>
                                          {v.img_obs_2 && <img src={URL_MAIN + v.img_obs_2} style={{ width: '150px', height: '150px', float: 'left' }} />}
                                          {v.img_obs_2 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.2</h6>}
                                        </th>
                                        <th>

                                          {v.img_obs_3 && <img src={URL_MAIN + v.img_obs_3} style={{ width: '150px', height: '150px', float: 'left' }} />}
                                          {v.img_obs_3 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.3</h6>}
                                        </th>
                                      </tr>
                                      <tr>

                                      </tr>
                                    </table>

                                  </AccordionTab>
                                ))
                              }

                            </Accordion>
                          </AccordionTab>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <br />
        </section>
      </section>
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default Statistiques;