import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { useReactToPrint } from 'react-to-print';
import { InputTextarea } from "primereact/inputtextarea";
import JoditEditor from 'jodit-react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useNavigate, useParams } from 'react-router-dom';
import { URL_AXIOS, URL_ENTETE_SITE, URL_FOLDER_API, URL_MAIN } from '../../URL/URL';
import axios from 'axios';
import { format } from 'date-fns';

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    backgroundColor: '#fff',
    overflowX: 'hidden',
    overflowY: 'scroll',

  },
  viewerBar: {
    width: '99%', //the pdf viewer will take up all of the width and height
    height: 50,
    backgroundColor: '#3b3b3b',
    borderBottom: '0.5px solid grey',
    position: 'fixed',
    zIndex: 1000

  },

  text: {
    fontSize: 10,
    textAlign: "center"
  },
  image: {
    width: 200,
    height: 200,
  },
});
function ApercuAvis() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [base64Image, setBase64Image] = useState('');
  const [siteName, setSiteName] = useState("");
  const [statutUrg, setStatutUrg] = useState(1);
  const [categorie_fichier, setCategorie_fichier] = useState("");
  const [Examen_1, setExamen_1] = useState('');
  const [Examen_2, setExamen_2] = useState('');
  const [chemin_fichier_1, setChemin_fichier_1] = useState('');
  const [chemin_fichier_2, setChemin_fichier_2] = useState('');
  const [bulletin_examen, setBulletin_examen] = useState('');
  const [chemin_bulletin, setChemin_bulletin] = useState('');
  const [nom_pat, setNom_pat] = useState('');
  const [age, setAge] = useState("");
  const [LibelleAge, setLibelleAge] = useState("");
  const [sexe, setSexe] = useState("");
  const [prescripteur, setPrescripteur] = useState("");
  const [observation, setObservation] = useState("");
  const [statut, setStatut] = useState("");
  const [etat, setEtat] = useState("");
  const [inter_id, setInter_id] = useState('');
  const [entete_societe, setEntete_societe] = useState("");
  const [ville_societe, setVille_societe] = useState(null);
  const [created_at, setCreated_at] = useState(new Date());
  const [img_obs_1, setImg_obs_1] = useState("");
  const [img_obs_2, setImg_obs_2] = useState("");
  const [img_obs_3, setImg_obs_3] = useState("");
  const [signature, setSignature] = useState("");
  const [nom_medecin, setNom_medecin] = useState("");
  const usernameconnected = sessionStorage.getItem("nom");
  const user_id = sessionStorage.getItem("user_id");
  const membre_id = sessionStorage.getItem("membre_id");
  const formattedDate = format(created_at, 'yyyy-MM-dd');
  const componentRef = useRef();
  const liste_dossiers_by_siteId = async (dossier_id) => {
    try {
      const response = await axios.get(URL_AXIOS + `liste_dossiers_by_dossiersId/${dossier_id}`);
      if (response.status == 200) {
        // setDossiers(response.data.dossier_send);
        setSiteName(response.data.dossier_send.societe);
        setCategorie_fichier(response.data.dossier_send.categorie_fichier);
        setExamen_1(response.data.dossier_send.nom_fichier)
        setExamen_2(response.data.dossier_send.nom_fichier_2)
        setChemin_fichier_1(response.data.dossier_send.chemin_fichier)
        setChemin_fichier_2(response.data.dossier_send.chemin_fichier_2)
        setBulletin_examen(response.data.dossier_send.bulletin_examen)
        setChemin_bulletin(response.data.dossier_send.chemin_bulletin)
        setNom_pat(response.data.dossier_send.nom_pat)
        setAge(response.data.dossier_send.age_pat)
        setSexe(response.data.dossier_send.sexe_pat)
        setLibelleAge(response.data.dossier_send.libelle_age_pat)
        setPrescripteur(response.data.dossier_send.prescripteur)
        setStatut(response.data.dossier_send.statut)
        setEtat(response.data.dossier_send.etat);
        setVille_societe(response.data.dossier_send.ville_societe);
        setCreated_at(response.data.dossier_send.created_at);
        setNom_medecin(response.data.dossier_send.nom_medecin);
        setSignature(response.data.dossier_send.signature_medecin);

        //const imageUrl = URL_FOLDER_API+"COGEMO_KINSOUNDI"+'/img/'+'entete_COGEMO_KINSOUNDI.jpg';
        const imageUrl = URL_FOLDER_API + siteName + "/img/" + response.data.dossier_send.entete_societe;
        //const encodedSiteName = encodeURIComponent(response.data.dossier_send.entete_societe);
        setEntete_societe(imageUrl);
        //setEntete_societe(imageUrl);


      }
    } catch (error) {
      alert("Erreur " + error.message);
    }
  }
  const config = {
    style: {
      position: 'absolute',

    },
    height:'auto',
    allowResizeX: false,
    allowResizeY: false,
    autofocus: true,
    toolbarAdaptive: false,
    editorClassName: 'editorComponent_apercu',
    zIndex: 0,
    readonly: false,
    toolbar: true,
    //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: true,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    statusbar: false,
    width: 'auto',
    direction: '',
    language: 'auto',
    debugLanguage: false,
    i18n: 'fr',
    tabIndex: -1,
    enter: "P",
    useSplitMode: false,
    colors: {
      greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
      palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
      full: [
        '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
        '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
        '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
        '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
        '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
        '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
      ]
    },
    colorPickerDefaultTab: 'background',
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    sizeLG: 900,
    sizeMD: 700,
    //sizeSM: 400,
    //sizeSM: 400,
    buttons: [

    ],
    /*buttonsXS: [
        'bold',
        'image', '|',
        'paragraph', '|',
        'align', '|',
        'undo', 'redo', '|',
        'eraser',
        'dots'
    ],*/
    events: {},
    textIcons: false,
  }
  const liste_dossiers_interprete = async (dossier_id) => {
    try {
      // if ((`statut == '1' || statut == '2'`)) {

      const response = await axios.get(URL_AXIOS + `liste_interpretation/${dossier_id}`);
      if (response.status === 200) {

        const obs = response.data.observation;
        //const regex = /(<([^>]+)>)/gi;
        //const result = obs.replace(regex, "");
        setObservation(obs);
        if (response.data.img_obs_1) {
          const URL1 = URL_MAIN + response.data.img_obs_1;
          setImg_obs_1(URL1);
        } else {
          setImg_obs_1("");
        }
        if (response.data.img_obs_2) {
          const URL2 = URL_MAIN + response.data.img_obs_2;
          setImg_obs_2(URL2);
        } else {
          setImg_obs_2("");
        }

        if (response.data.img_obs_3) {
          const URL3 = URL_MAIN + response.data.img_obs_3;
          setImg_obs_3(URL3);
        } else {
          setImg_obs_3("");
        }


        setInter_id(response.data.inter_id);







        /*} else {

        }*/
      }

    } catch (error) {
      alert("Erreur " + error.message);
    }
  }
  const getImageUrl = (imageName) => {
    return URL_FOLDER_API + siteName + '/img/' + imageName;
  };
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    try {
      const FetchData = async (dossier_id) => {
        liste_dossiers_by_siteId(dossier_id);
        liste_dossiers_interprete(dossier_id);
      }
      FetchData(id);
    } catch (error) {

    }

  }, [id, entete_societe, signature])
  return (
    <div style={styles.viewer} >
      <div style={styles.viewerBar}>
        <div className='row'>
          <div className='col col-md-12'>
            <center><button type='button' className='btn btn-primary' onClick={() => HandlePrint()}><i className='fa fa-print' style={{ color: 'white', fontSize: 20, marginTop: 10 }}></i></button></center>
          </div>

        </div>
      </div>
      <br />  <br />
      <section className='A4 portrait page_main' ref={componentRef}>

        <section class="sheet padding-10mm">
       {/*   <center><h6 className='text-center' style={{ color: 'black' }}>© E-METECH, plateforme de téléradiologie, www.e-metech.com</h6></center>*/}
          <article>
            <table style={{ width: "100%" }}>
              <tr>
                <p style={{ fontSize: 12, marginTop: 5, float: 'right', color: 'black', fontWeight: 'bold' }}>Fait à {ville_societe} le, {format(created_at, 'dd/MM/yyyy')}</p>
              </tr>
              <tr>
                <h3 className='text-center' style={{ marginTop: "1%", color: 'black', textDecoration: "underline" }}>{categorie_fichier}</h3>
              </tr>
            </table>
            <hr style={{borderColor:'black'}}/>
            <table className='table table-striped' style={{ marginLeft: 30 }}>
              <tr style={{ color: 'black', fontSize: 12 }}>
                <th>ID : {id}</th>
                <th>NOMS: {nom_pat}</th>
                <th>SEXE: {sexe}</th>
              </tr>
              <tr>
                <br />
              </tr>
              <tr style={{ color: 'black', fontSize: 12 }}>
                <th>AGE: {age + " " + LibelleAge}</th>
                <th>PRESCRIPTEUR: {prescripteur}</th>
                <th>RADIOLOGUE : {nom_medecin}</th>
              </tr>

            </table>
            <hr style={{borderColor:'black'}}/>
            <table style={{ width: "100%" }}>
              <tr>
                <JoditEditor
                  config={config}
                  ref={editor}
                  value={observation}
                  tabIndex={1}
                />
              </tr>
            </table>
            <table style={{ marginTop: 10, width: "100%" }}>
              <tr>
                <th>
                  {img_obs_1 && <img src={img_obs_1} style={{ width: '150px', height: '150px', float: 'left' }} />}
                  {img_obs_1 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.1</h6>}
                </th>
                <th>
                  {img_obs_2 && <img src={img_obs_2} style={{ width: '150px', height: '150px', float: 'left' }} />}
                  {img_obs_2 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.2</h6>}
                </th>
                <th>
                  {img_obs_3 && <img src={img_obs_3} style={{ width: '150px', height: '150px', float: 'left' }} />}
                  {img_obs_3 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.3</h6>}
                </th>
              </tr>
              <tr>

              </tr>
            </table>
            <table style={{width:'100%'}}>
              <tr>
                <th>
                  <p style={{ marginTop: '20px', fontWeight: 'bold', color: "black" }}>Bien confraternellement !<br />
                    Examen interprété dans le cadre de la téléradiologie
                  </p>
                </th>
                <th>
                  {signature && <img src={URL_MAIN + "signature_membre" + "/" + signature} style={{ float: 'right',width: '150px', height: '100px' }} />}
                </th>
              </tr>
            </table>
          </article>

        </section>
      </section>
    </div>

  );
}

export default ApercuAvis;