import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import EditorApi from './Editor';
import { ThemeContext } from '../../context/ContextHeader';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Editor } from 'primereact/editor';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import Select from 'react-select'
import Micro from './Micro';
import { Dialog } from 'primereact/dialog';
import { ReactCrop } from 'react-image-crop';
import { title } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { useCallback } from 'react';
import { useMemo } from 'react';
function ModelCompteRendu() {
    const { text, setText, datedebut, dossiersEn, setDossiersEn, dossiers, setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [optionValue, setOptionValue] = useState("fr-FR");

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const NEW_LINE = '\n';
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [image, setImage] = useState(null);
    const [output, setOutput] = useState(null);
    const [src_1, setSrc_1] = useState(null);
    const [crop_1, setCrop_1] = useState({ aspect: 16 / 9 });
    const [image_1, setImage_1] = useState(null);
    const [output_1, setOutput_1] = useState(null);
    const [src_2, setSrc_2] = useState(null);
    const [crop_2, setCrop_2] = useState({ aspect: 16 / 9 });
    const [image_2, setImage_2] = useState(null);
    const [output_2, setOutput_2] = useState(null);
    const [statusFont, setStatusFont] = useState(false);
    const [visible, setVisible] = useState(false);
    const [titreCompteRendu, setTitreCompteRendu] = useState("");
    const [observation, setObservation] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [fontSize, setFontSize] = useState(12);
    const [statusRecord, setStatusRecord] = useState(false);
    const [aleaNumberCr, setAleaNumberCr] = useState(0);
    const [aleaNumberCrSession, setAleaNumberCrSession] = useState(parseInt(sessionStorage.getItem('alea')));
    const [mcr, setMcr] = useState([]);
    const [messageError, setMessageError] = useState(false);
    const [visibleOne, setVisibleOne] = useState(false);
    const useRefTitre = useRef(null);
    const editorRef = useRef(null);
    const [content, setContent] = useState('');
    const [mcrId, setMcrId] = useState('');
    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
    };
    const selectImage_1 = (file) => {
        setSrc_1(URL.createObjectURL(file));
    };
    const selectImage_2 = (file) => {
        setSrc_2(URL.createObjectURL(file));
    };

    const cropImageNow = () => {
        const imageL = document.getElementById("cropp");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput(base64Image);
    };

    const cropImageNow_1 = () => {
        const imageL = document.getElementById("cropp1");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop_1.width;
        canvas.height = crop_1.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop_1.width * pixelRatio;
        canvas.height = crop_1.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop_1.x * scaleX,
            crop_1.y * scaleY,
            crop_1.width * scaleX,
            crop_1.height * scaleY,
            0,
            0,
            crop_1.width,
            crop_1.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput_1(base64Image);
    };

    const cropImageNow_2 = () => {
        const imageL = document.getElementById("cropp2");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop_2.width;
        canvas.height = crop_2.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop_2.width * pixelRatio;
        canvas.height = crop_2.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop_2.x * scaleX,
            crop_2.y * scaleY,
            crop_2.width * scaleX,
            crop_2.height * scaleY,
            0,
            0,
            crop_2.width,
            crop_2.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput_2(base64Image);
    };

    const list_mcr = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `list_mcr/${membre_id}`);
            if (response.data.status === 200) {
                setMcr(response.data.mcr);
            }
        } catch (Error) {

        }
    }

    const addMcr = async () => {
        try {
            //var editeurValue = document.querySelector(".editor").innerHTML;

            if (content !== "" && titreCompteRendu != "") {

                if (mcrId !== "") {
                    const data = {
                        titre: titreCompteRendu,
                        observation: content,
                        statut: '0',
                        id_membre: membre_id,
                        id_mcr: mcrId
                    }
                    const response = await axios.post(URL_AXIOS + 'updateMcr', data);
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: 'Informations',
                            text: 'Les données ont été enregistrées avec succès !',
                            icon: 'success',

                        })
                        setTitreCompteRendu("");
                        window.copyButton();
                        setMcrId("");
                        setContent("")
                        list_mcr();
                        setMessageError(false)
                    } else {
                        alert("Erreur savings")
                    }
                } else {
                    const data = {
                        titre: titreCompteRendu,
                        observation: content,
                        statut: '0',
                        id_membre: membre_id,

                    }
                    const response = await axios.post(URL_AXIOS + 'addMcr', data);
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: 'Informations',
                            text: 'Les données ont été enregistrées avec succès !',
                            icon: 'success',

                        })
                        setTitreCompteRendu("");
                        window.copyButton();

                        setContent("")
                        list_mcr();
                        setMessageError(false)
                    } else {
                        alert("Erreur savings")
                    }
                }


            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez mettre le titre du compte rendu svp !',
                    icon: 'error',

                })
                useRefTitre.current.focus();
                setMessageError(true)
            }
        } catch (error) {
            alert("erreur 12" + error)
        }
    }

    const deleteMcr = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_mcr/${id}`);
            if (response.data.status === 200) {
                list_mcr();
                setMcrId("");
            }
        } catch (error) {

        }
    }

    const deleteConfirmMrc = (id) => {
        try {
            Swal.fire({
                title: "Suppression",
                text: "Êtes-vous sûr de vouloir supprimer ce compte rendu",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Suppression!",
                        text: "Le compte rendu a été supprimé avec succès !",
                        icon: "success"
                    });
                    deleteMcr(id);
                }
            });
        } catch (error) {

        }
    }
    const startRecord = (event) => {
        try {
            if (window.variables.recognizing) {
                window.isToRestartImmediately = false
            }
            window.startButton(event)
        } catch (error) {

        }

    }

    const formattedDate = currentDate.toLocaleDateString('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    const enregistrer = (id) => {
        document.getElementById(id).submit();
    }

    const maj = (id) => {
        document.getElementById(id).submit();
    }

    const publier = (id) => {
        document.getElementById(id).submit();
    }

    const etat_disponible = (id) => {
        document.getElementById(id).submit();
    }

    const etat_indisponible = (id) => {
        document.getElementById(id).submit();
    }

    const exp_oui = (id) => {
        document.getElementById(id).submit();
    }

    const exp_non = (id) => {
        document.getElementById(id).submit();
    }

    const upload_img_rc = (id) => {
        document.getElementById(id).submit();
    }

    const upload_img_rlt = (id) => {
        document.getElementById(id).submit();
    }

    const save_modele = (id) => {
        document.getElementById(id).submit();
    }

    const upload_signature = (id) => {
        document.getElementById(id).submit();
    }

    const search = (id) => {
        document.getElementById(id).submit();
    }


    const loadDataMcr = (val) => {
        try {
            setTitreCompteRendu(val.titre);
            setMcrId(val.id)
            window.insertSymbol(val.observation);
        } catch (error) {

        }

    }

    const config = {
        style: {
            position: 'absolute',

        },
        allowResizeX: false,
        allowResizeY: false,
        autofocus: false,
        toolbarAdaptive: false,
        editorClassName: 'editorComponent',
        zIndex: 0,
        readonly: false,
        //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots','fontsize'],
        //allowCommandsInReadOnly: ['selectall', 'preview', 'print','fontsize'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: true,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: "440",
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'fr',
        tabIndex: -1,
        toolbar: true,
        // enter: "P",
        useSplitMode: false,
        colors: {
            greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'background',
        removeButtons: [],
        disablePlugins: [],
        extraButtons: [],
        sizeLG: 900,
        sizeMD: 700,
        //sizeSM: 400,
        //sizeSM: 400,
        buttons: [
            'source', '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'print'
        ],
        /*buttonsXS: [
            'bold',
            'image', '|',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],*/
        events: {},
        textIcons: false,
    }

    const EditeurText = useMemo(() => (


        <JoditEditor
            config={config}
            ref={editorRef}
            value={content}
            onChange={newContent => setContent(newContent)}
        />
    ), []);
    const contentEditor = () => {
        return (
            <div>
                <div id="right_options_detail_mcr"></div>
                <div className='row'>
                    <div className=' card' style={{ height: 60 }}>
                        <div class="row" style={{ padding: 5, margin: 0, background: '#fff' }}>
                            <div className='col col-md-3'>

                            </div>
                            <div class="col-md-6 pr-1">
                                <input type="text" name="titre"
                                    value={titreCompteRendu}
                                    onChange={(e) => setTitreCompteRendu(e.target.value)}
                                    ref={useRefTitre}
                                    class="form-control" placeholder="Titre compte rendu " data-toggle="tooltip" data-placement="bottom" title="" autocomplete="titre" style={{ marginTop: 9 }} />
                            </div>
                            <div class="col-md-3 pr-1">
                                <input type="hidden" name="nom_pat" id="nom_pat" class="form-control" placeholder="Noms patient" data-toggle="tooltip" data-placement="bottom" title="Noms patient" autocomplete="nom_pat" style={{ borderBottom: 'none', borderLeft: '1px solid #ccc' }} />
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    {/*<!-- DEBUT 1ere Colonne button save & update -->*/}
                    <div class="col-md-1" style={{ height: '8vh', overflow: 'hidden' }} >
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> </h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive-md">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button type="button" className="btn btn-default save"
                                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                                            onClick={() => addMcr()}
                                                        >
                                                            <i class="fa fa-mobile " data-toggle="tooltip" data-placement="bottom" title="Enregistrer"></i>
                                                        </button>
                                                        {/*<!-- Modal -->*/}
                                                        <div class="modal fade" id="saveModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        Que voulez-vous faire ?
                                                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <p style={{ textAlign: 'center', color: 'red' }}>Si votre compte rendu, a subi des modifications, veuillez d’abord effectuée une mise à jour, avant de l'enregistrer comme modèle personnalisé.</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                {/* <!-- <input type="submit" onclick="maj('form{{ 2 }}');" value="Mise à jour du compte rendu" class="btn btn-danger" style="width: 100%;"> -->*/}
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                {/* <!-- <input type="button" data-toggle="modal" data-target="#titreMModal" onclick="$('#saveModal').modal('toggle');" value="Enregistrer comme modèle personnalisé" class="btn btn-success" style="width: 100%;"> -->*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<!-- <button type="submit" onclick="enregistrer('form{{ 1 }}')" class="btn btn-default save" style="width: 100%; height: 100%;">
                                                            <i class="now-ui-icons tech_mobile" data-toggle="tooltip" data-placement="bottom" title="Enregistrer" style="color: green;"></i>
                                                            </button> -->*/}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button type="button" data-toggle="modal" data-target="#sendModal" className="btn btn-default send" style={{ width: '100%', height: '100%', display: "none" }}>
                                                            <i className="fa fa-send now-ui-icons ui-1_send" data-toggle="tooltip" data-placement="bottom" title="Envoyer les résultats"></i>
                                                        </button>
                                                        {/* <!-- Modal Send-->*/}
                                                        <div className="modal fade" id="sendModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        Question
                                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <p style={{ fontSize: '1.2em' }}>Êtes vous sûr de vouloir envoyer ces résultats ?</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row">
                                                                            <div className="col-md-3"></div>
                                                                            <div className="col-md-3">
                                                                                {/*<!-- <input type="button" onclick="publier('form{{ 3 }}')" value="Oui" className="btn btn-danger" style="width: 100%;"> -->*/}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {/*<!-- <input type="button" value="Non" onclick="$('#sendModal').modal('toggle');" className="btn btn-dark" style="width: 100%;"> -->*/}
                                                                            </div>
                                                                            <div className="col-md-3"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*<!-- FIN 1ere Colonne button save & update -->*/}

                    {/*<!-- DEBUT 2eme Colonne editeur -->*/}
                    <div className="col-md-10 pr-1" style={{ height: '72vh', overflow: 'hidden' }}>
                        {/* <!-- foreach($liste_dossiers_pl as $dossier) -->*/}


                        {/* <!-- endforeach -->*/}

                        <div className="row">
                            <div className="col-md-12">
                                <div id="app" style={{ margin: 0, padding: 0, height: 0, width: 0, backgroundColor: 'black' }}></div>
                                <div id="app_container">
                                    <div id="menu_pane" className="water_mark">
                                        <div id="menu_container">
                                            <button type="button" className="menuhead" id="preferences_button">
                                                <ul className="dropdownmenu" id="preferences">
                                                    <li>
                                                        <input type="checkbox" id="save_checkbox" /><label for="save_checkbox" id="lbl_save_checkbox">Enregistrement automatique</label>
                                                    </li>
                                                </ul>
                                            </button>

                                            <button type="button" id="new_button" title="Nouvelle session" onClick={(event) => window.newButton(event)}></button>
                                            <button type="button" className="menuhead" id="open_button">
                                                <ul className="dropdownmenu" id="select_session">
                                                    {/*<!-- TODO: clean "openfile" from old speechnotes & speechlogger - both in layout and in code -->*/}
                                                    <div id="sessions_menu"></div>
                                                </ul>
                                            </button>
                                            <button type="button" id="email_button" title="Email" onClick={(event) => window.emailButton(event)}></button>
                                            <button type="button" className="menuhead" id="export_button">
                                                <ul class="dropdownmenu" id="export_menu">
                                                    <li>
                                                        <div className="exportOption" id="save_txt" onClick={() => window.saveAsTxtFile()}>Télécharger le
                                                            fichier
                                                            (.txt)</div>
                                                    </li>
                                                    <li>
                                                        <div className="exportOption" id="save_doc" onClick={() => window.saveAsDocFile()}>Document Word (.doc)
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="exportOption" id="save_to_disc" onClick={(event) => window.saveButton(event)}>Sauvegarder la
                                                            session (Ctrl+S)</div>
                                                    </li>
                                                </ul>
                                            </button>
                                            <button type="button" id="print_button" title="Impression" onClick={(event) => window.printButton(event)}></button>

                                            <button type="button" id="enlarge_button" title="Zoom avant" onClick={() => window.zoom(1.20)}></button>
                                            <button type="button" id="shrink_button" title="Zoom arrière" onClick={() => window.zoom(1 / 1.20)}></button>
                                        </div>
                                    </div>

                                    <div id="application_pane">
                                        <div id="application_header">
                                            <div id="session_title" style={{ color: 'black', fontWeight: '600', fontSize: '13px', color: 'green' }} contenteditable="true"></div>
                                            <hr />
                                            <div id="buttons_row">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <select id="select_language" onchange="updateLanguage();"></select>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div id="start_button" title="Démarrer / Pause" onClick={startRecord}><img alt="Démarrer" id="start_img" src="../../micoff2.png" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="output_box">
                                            <div id="alerts"></div>
                                            <div className='editor' >
                                                {EditeurText}
                                            </div>


                                            <div className="btn-toolbar" data-role="editor-toolbar" data-target="#editor">
                                                {/*  <div className="btn-group">
                                                    <a className="btn" data-edit="undo" title="Undo (Ctrl/Cmd+Z)"><i className="icon-undo"></i></a>
                                                    <a className="btn" data-edit="redo" title="Redo (Ctrl/Cmd+Y)"><i className="icon-repeat"></i></a>
                                                        </div>*/}
                                                {/*   <div className="btn-group">
                                                    <a className="btn dropdown-toggle" data-toggle="dropdown" title="Font"><i className="icon-font"></i></a>
                                                    <ul className="dropdown-menu">
                                                    </ul>
                                                    </div>*/}
                                                {/*<div className="btn-group">
                                                    <a className="btn dropdown-toggle" data-toggle="dropdown" title="Font Size"><i className="icon-text-height"></i></a>
                                                    <ul className="dropdown-menu">
                                                        <li><a data-edit="fontSize 1">
                                                            <font size="1">8</font>
                                                        </a></li>
                                                        <li><a data-edit="fontSize 2">
                                                            <font size="2">9</font>
                                                        </a></li>
                                                        <li><a data-edit="fontSize 3">
                                                            <font size="3">11</font>
                                                        </a></li>
                                                        <li><a data-edit="fontSize 4">
                                                            <font size="4">12</font>
                                                        </a></li>
                                                        <li><a data-edit="fontSize 5">
                                                            <font size="5">14</font>
                                                        </a></li>
                                                        <li><a data-edit="fontSize 6">
                                                            <font size="6">16</font>
                                                        </a></li>
                                                    </ul>
                                                </div>*/}
                                                { /*<div className="btn-group">
                                                    <a className="btn" data-edit="bold" title="Bold (Ctrl/Cmd+B)"><i className="icon-bold"></i></a>
                                                    <a className="btn" data-edit="italic" title="Italic (Ctrl/Cmd+I)"><i className="icon-italic"></i></a>
                                                    <a className="btn" data-edit="strikethrough" title="Strikethrough"><i className="icon-strikethrough"></i></a>
                                                    <a className="btn" data-edit="underline" title="Underline (Ctrl/Cmd+U)"><i className="icon-underline"></i></a>
                                            </div>*/}
                                                {  /*<div class="btn-group">
                                                    <a class="btn" data-edit="insertunorderedlist" title="Bullet list"><i class="icon-list-ul"></i></a>
                                                    <a class="btn" data-edit="insertorderedlist" title="Number list"><i class="icon-list-ol"></i></a>
                                                    <a class="btn" data-edit="outdent" title="Reduce indent (Shift+Tab)"><i class="icon-indent-left"></i></a>
                                                    <a class="btn" data-edit="indent" title="Indent (Tab)"><i class="icon-indent-right"></i></a>
                                        </div>*/}
                                                { /*<div class="btn-group">
                                                    <a class="btn" data-edit="justifyleft" title="Align Left (Ctrl/Cmd+L)"><i class="icon-align-left"></i></a>
                                                    <a class="btn" data-edit="justifycenter" title="Center (Ctrl/Cmd+E)"><i class="icon-align-center"></i></a>
                                                    <a class="btn" data-edit="justifyright" title="Align Right (Ctrl/Cmd+R)"><i class="icon-align-right"></i></a>
                                                    <a class="btn" data-edit="justifyfull" title="Justify (Ctrl/Cmd+J)"><i class="icon-align-justify"></i></a>
                                    </div>*/}
                                                {/*<div class="btn-group">
                                                    <a class="btn dropdown-toggle" data-toggle="dropdown" title="Hyperlink"><i class="icon-link"></i></a>
                                                    <div class="dropdown-menu input-append">
                                                        <div class="row">
                                                            <div class="col-md-8">
                                                                <input class="span2" placeholder="URL" type="text" data-edit="createLink" />
                                                            </div>
                                                            <div class="col-md-4">
                                                                <button class="btn" className='' type="button"><i class="now-ui-icons ui-1_check"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a class="btn" data-edit="unlink" title="Remove Hyperlink"><i class="icon-cut"></i></a>
                                </div>*/}

                                                {/* <div class="btn-group">
                                                    <button type='button' onClick={() => setVisible(true)} className="btn btn-default img_attach" style={{ width: "100%" }}>
                                                        <i class="icon-picture" data-toggle="tooltip" data-placement="top" title="Joindre des images sur résultats."></i>
                                                    </button>
                            </div>*/}

                                            </div>
                                            <span className='img_attach'></span>
                                            {/*<!-- Deuxieme editeur mise en forme -->*/}






                                            {/*<!-- TEXTAREA Clone, pas visible -->*/}
                                            <textarea name="observation" style={{ display: 'none' }}></textarea>

                                            {/*  <!-- Premier editeur de dictée vocale -->*/}
                                            <textarea name="results_box" class="resultsClass widebox" id="results_box" resizeable="false" onScroll={() => window.rememberScroll()} onBlur={() => window.rememberScroll()} onFocus={() => window.scrollToMemory()} placeholder="RENSEIGNEMENT CLINIQUE:
    
    
    TECHNIQUE:
                                        
    DESCRIPTION:
                                        
                                        
                                        
                                        
    
    
    CONCLUSION:">

                                            </textarea>
                                        </div>

                                        <div id="mirror_container">
                                            <div id="mirror"></div>
                                        </div>
                                        {/*   <!-- @si (resultat) -->
    
                                <!-- @si non -->
                                        <!-- @fin si -->*/}
                                        <div className=" copyButton" onClick={() => window.copyButton()} title="Transcrire"></div>


                                    </div>{/*<!-- application_pane -->
                            <!-- Modal Astuce -->*/}
                                    <div class="modal right fade" id="astuceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    Divers
                                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    <div>
                                                        <p><b>Astuce :</b> Pendant que vous dictez, appuyez sur Entrée ↵ (sur le clavier) pour déplacer
                                                            rapidement
                                                            les résultats dans l'éditeur de texte.
                                                        </p>
                                                    </div>
                                                    <table id="commands_table">
                                                        <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('Bien confraternellement !')}>
                                                            <td id="cell_sad">Salutation</td>
                                                            <td>Bien confraternellement !</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Modal Commandes vocales et Raccourcis -->*/}


                                    <div class="modal right fade" id="cvrModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    Commandes vocales et Raccourcis
                                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    <div>
                                                        <table id="commands_table">
                                                            <tr>
                                                                <th id="cell_say">Dire</th>
                                                                <th>Pour insérer</th>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('RENSEIGNEMENTS CLINIQUES:')}>
                                                                <td id="cell_sad">RENSEIGNEMENTS CLINIQUES</td>
                                                                <td>RENSEIGNEMENTS CLINIQUES</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('TECHNIQUE:')}>
                                                                <td id="cell_sad">TECHNIQUE</td>
                                                                <td>TECHNIQUE</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('DESCRIPTION:')}>
                                                                <td id="cell_sad">DESCRIPTION</td>
                                                                <td>DESCRIPTION</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('CONCLUSION:')}>
                                                                <td id="cell_sad">CONCLUSION</td>
                                                                <td>CONCLUSION</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol(NEW_LINE)}>
                                                                <td id="cell_line">Nouvelle ligne</td>
                                                                <td>↵</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol(NEW_LINE + NEW_LINE)}>
                                                                <td id="cell_paragraph">Nouveau paragraphe</td>
                                                                <td>↵↵</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('.')}>
                                                                <td id="cell_period">Point final</td>
                                                                <td>.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol(',')}>
                                                                <td id="cell_comma">Virgule</td>
                                                                <td>,</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('?')}>
                                                                <td id="cell_question">Point d'interrogation</td>
                                                                <td>?</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol(':')}>
                                                                <td id="cell_colon">Deux-points</td>
                                                                <td>:</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol(';')}>
                                                                <td id="cell_semi">Point-virgule</td>
                                                                <td>;</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('!')}>
                                                                <td id="cell_exclamation">Point d'exclamation</td>
                                                                <td>!</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('-')}>
                                                                <td id="cell_dash">Tiret</td>
                                                                <td>-</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('(')}>
                                                                <td id="cell_open">Ouvrir la parenthèse</td>
                                                                <td>(</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol(')')}>
                                                                <td id="cell_close">Fermer la parenthèse</td>
                                                                <td>)</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<!-- Modal Techniques -->*/}
                                    <div class="modal right fade" id="techModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    Techniques
                                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    <div>
                                                        <table id="commands_table">
                                                            <tr>
                                                                <th id="cell_say">Scanner</th>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('Acquisition spiralée sans injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                                <td id="cell_sad">Acquisition spiralée sans injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('Acquisition spiralée sans et avec injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                                <td id="cell_sad">Acquisition spiralée sans et avec injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                            </tr>
                                                            <tr>
                                                                <th id="cell_say">IRM</th>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.')}>
                                                                <td id="cell_sad">Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.')}>
                                                                <td id="cell_sad">Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.')}>
                                                                <td id="cell_sad">Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('DP Fastat dans les trois plans, T1 sagittales')}>
                                                                <td id="cell_sad">DP Fastat dans les trois plans, T1 sagittales.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => window.insertSymbol('T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO')}>
                                                                <td id="cell_sad">T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO.</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  <!-- Modal Model CR -->*/}
                                    <div class="modal right fade" id="modelModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    Modèles comptes rendus
                                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    <div>
                                                        <table id="commands_table">
                                                            {mcr.map((val) => (
                                                                <div>
                                                                    <tr title="Cliquez pour insérer" onClick={() => loadDataMcr(val)}>

                                                                        <td id="cell_sad" >{val.titre} </td>
                                                                        <td>
                                                                            <button className='btn btn-danger' onClick={() => deleteConfirmMrc(val.id)} style={{}}>
                                                                                <i className='fa fa-remove' style={{ color: 'white' }}></i>
                                                                            </button>
                                                                        </td>



                                                                    </tr>

                                                                </div>
                                                            ))

                                                            }
                                                            {mcr.length <= 0 && <p>Pas de modèles enregistrés</p>}
                                                        </table>
                                                        <hr />
                                                        <table id="commands_table">
                                                            { /*<!-- foreach($mcr as $cr) -->
                                                    <tr title="Cliquez pour insérer" onclick="insertSymbol(&#39; observation &#39;)">
                                                        <!-- <td id="cell_sad">{ $cr->titre }</td> -->
                                                        <td id="cell_sad">titre cr</td>
                                                    </tr>
                                                <!-- endforeach -->*/}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  <!-- FIN 2eme Colonne editeur-- >
    
        < !--DEBUT 3eme Colonne button aperçu, model cr, (button declencheur de modal)-->*/}
                    <div class="col-md-1" style={{ height: '72vh', overflow: 'hidden' }} id='mcr_panel_option'>
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> </h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive-md">
                                        <table class="table">
                                            <tbody>
                                           
                                                <tr>
                                                    <td>
                                                        <button type="button" data-toggle="modal" data-target="#modelModal" class="btn btn-default model" style={{ width: '100%', height: '100%', border: 'none' }}>
                                                            <i class="fa fa-book files_paper" data-toggle="tooltip" data-placement="bottom" title="Charger un modèle de compte rendu"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr id="cvr">
                                                    <td >

                                                        <a href="#" data-toggle="modal" data-target="#cvrModal" class="btn btn-default" style={{ width: '100%', border: 'none' }} >
                                                            <i class="fa fa-gear" data-toggle="tooltip" data-placement="bottom" title="Options"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr id="tech">
                                                    <td>
                                                        <a href="#" data-toggle="modal" data-target="#techModal" class="btn btn-default" style={{ width: '100%', border: 'none' }}>
                                                            <i class="fa fa-tags shopping_tag-content" data-toggle="tooltip" data-placement="bottom" title="Techniques"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr id="astuce">
                                                    <td>
                                                        <a href="#" data-toggle="modal" data-target="#astuceModal" class="btn btn-default" style={{ width: '100%', border: 'none' }}>
                                                            <i class="now-ui-icons fa fa-lightbulb-o" data-toggle="tooltip" data-placement="bottom" title="Divers"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div >
        )
    }
    useEffect(() => {
        setDisableAvis(true);
        list_mcr();
        //window.newButton(event)
        // window.updateLanguage();

        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
        window.runOnload();
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <Dialog header="Pièces jointes" position='top' visible={visible} onHide={() => setVisible(false)}
                        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                        <div className='container-fluid'>
                            <form>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col col-md-11'>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    selectImage(e.target.files[0]);
                                                }}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col col-md-1'>
                                            <h6>Fig.1</h6>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col col-md-6'>
                                            <div style={{ width: "100%", height: "30%" }}>
                                                {src && (
                                                    <div>
                                                        <ReactCrop onComplete={cropImageNow}
                                                            crop={crop} onChange={setCrop} >
                                                            <img src={src} id='cropp' style={{ width: "100%", height: "30%" }} />
                                                        </ReactCrop>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col col-md-6'>
                                            <div style={{ width: "100%", height: "50%" }}>
                                                {output && <img src={output} style={{ width: "100%", height: "50%" }} />}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='form-group'>

                                    <div className='row'>
                                        <div className='col col-md-11'>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    selectImage_1(e.target.files[0]);
                                                }}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col col-md-1'>
                                            <h6>Fig.2</h6>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col col-md-6'>
                                            <div style={{ width: "100%", height: "30%" }}>
                                                {src_1 && (
                                                    <div>
                                                        <ReactCrop onComplete={cropImageNow_1}
                                                            crop={crop_1} onChange={setCrop_1} >
                                                            <img src={src_1} id='cropp1' style={{ width: "100%", height: "30%" }} />
                                                        </ReactCrop>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col col-md-6'>
                                            <div style={{ width: "100%", height: "50%" }}>
                                                {output_1 && <img src={output_1} style={{ width: "100%", height: "50%" }} />}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col col-md-11'>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    selectImage_2(e.target.files[0]);
                                                }}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col col-md-1'>
                                            <h6>Fig.3</h6>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col col-md-6'>
                                            <div style={{ width: "100%", height: "30%" }}>
                                                {src_2 && (
                                                    <div>
                                                        <ReactCrop onComplete={cropImageNow_2}
                                                            crop={crop_2} onChange={setCrop_2} >
                                                            <img src={src_2} id='cropp2' style={{ width: "100%", height: "30%" }} />
                                                        </ReactCrop>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col col-md-6'>
                                            <div style={{ width: "100%", height: "50%" }}>
                                                {output_2 && <img src={output_2} style={{ width: "100%", height: "50%" }} />}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='form-group'>
                                    <button type='button' onClick={() => setVisible(false)} className='btn btn-success form-control'>Enregistrer</button>
                                </div>
                            </form>
                        </div>
                    </Dialog>
                    {contentEditor()}

                </section>
            </section>
            <Footer />
        </div>
    );
}

export default ModelCompteRendu;