import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { Editor } from "primereact/editor";
import Swal from 'sweetalert2/dist/sweetalert2.js'


function AddNotification() {
    const { datedebut, dossiersEn, setDossiersEn, dossiers, setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis
    } = useContext(ThemeContext);

    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");

    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [objet, setObjet] = useState("");
    const [contenu, setContenu] = useState("");
    const [user_id_sender, setUser_id_sender] = useState(11);
    const [user_id_reception, setUser_id_reception] = useState(0);

    const navigate = useNavigate();
    const [membres, setMembres] = useState([]);
    const [text, setText] = useState('');
    const [selected, setSelected] = useState([]);
    const [validaStatus, setValidaStatus] = useState(false);
    const toast = useRef(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const AlertSave = () => {
        Swal.fire({
            title: 'Informations',
            text: 'Les données ont été enregistrées avec succès !',
            icon: 'success',

        })
    }
    const addNotificat = async () => {
        try {
            if (objet !== "" && text !== "") {


                const formData = {
                    objet: objet,
                    contenu: text,
                    user_id_sender: user_id,
                    user_id_reception: selected,
                    file: selectedFile
                }
                let verifier = 0;
                const res = await axios.post(URL_AXIOS + "addNotifications", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                    },
                });
                if (res.data.status === 200) {
                    const message = "Les informations ont été enregistrées";
                    alert(message)
                    AlertSave();
                    setText("");
                    setObjet('');
                    setSelected([]);
                    setSelectedFile(null);
                } else {
                    //alert("Veuillez saisir toutes les informations " + selected);

                }




            } else {
               // alert("Veuillez saisir toutes les informations ici");
            }
        } catch (error) {
            //alert("Error saving : " + error.message)
        }

    }
    /*const addNotificat = async () => {
        try {
          if (!objet || !text || !user_id_sender || selected.length === 0) {
            alert("Veuillez saisir toutes les informations nécessaires.");
            return;
          }
      
          const formData = new FormData();
          formData.append("objet", objet);
          formData.append("contenu", text.replace(/<[^>]*>/g, ''));
          formData.append("user_id_sender", user_id_sender);
      
          // Assuming "selected" is an array of user IDs, append each one
          for (const id of selected) {
            formData.append("user_id_reception[]", id);
          }
      
          if (selectedFile) {
            formData.append("file", selectedFile);
          }
      
          const res = await axios.post(URL_AXIOS + "addNotifications", formData,{});
      
          if (res.data.status === 200) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Les informations ont été enregistrées !' });
            setText("");
            setObjet('');
            setSelected([]);
            setSelectedFile(null);
          } else {
            // Display a meaningful error message from the server
            alert("Erreur: " + res.data.data_error);
          }
        } catch (error) {
          console.error("Error sending notification: ", error.message);
          // Display a user-friendly error message
          alert("Une erreur s'est produite lors de l'envoi de la notification. Veuillez réessayer plus tard.");
        }
      };*/

    /* const addNotification = async () => {
 try {
   // Check if all required information is provided
   if (!objet || !text || !user_id_sender || selected.length === 0) {
     alert("Veuillez saisir toutes les informations nécessaires.");
     return;
   }

   // Create an object to hold the notification data
   const notificationData = {
     objet: objet,
     contenu: text.replace(/<[^>]*>/g, ''),
     user_id_sender: user_id_sender,
     user_id_reception: selected,
   };

   // Create a FormData object to send the file (if selectedFile is provided)
   const formData = new FormData();
   if (selectedFile) {
     formData.append("file", selectedFile);
   }

   // If a file is provided, add it to the notificationData as well
   if (selectedFile) {
     notificationData.file = selectedFile;
   }

   // Send a POST request to the server to add the notification with JSON data
   const response = await axios.post(URL_AXIOS + "addNotifications", notificationData, {});

   if (response.data.status === 200) {
     // Notification successfully added
     toast.current.show({ severity: 'success', summary: 'Success', detail: 'Les informations ont été enregistrées !' });

     // Reset form fields
     setText("");
     setObjet('');
     setSelected([]);
     setSelectedFile(null);
   } else {
     // Display an error message from the server
     alert("Erreur: " + response.data.data_error);
   }
 } catch (error) {
   console.error("Error sending notification: ", error.message);
   // Display a user-friendly error message
   alert("Une erreur s'est produite lors de l'envoi de la notification. Veuillez réessayer plus tard.");
 }
};
*/



    const handleSelected = (selected) => {
        setSelected(selected)
    }
    const handleMultiSelectChange = (e) => {
        // Assuming e.value is an array of selected values
        setSelected(e.value);
    };
    const data_membre = membres.map((item => ({
        label: item.name,
        value: item.id

    })));
    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                setMembres(response.data.users_sites);
            }
        } catch (Error) {

        }
    }
    useEffect(() => {
        setDisableAvis(true);
        liste_membres();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='row ' >

                        <div class="form-panel">
                            <form role="form" class="form-horizontal style-form">
                                <div className="form-group">
                                    <div className='col col-md-6'>
                                        <input type='text' value={objet} onChange={(e) => setObjet(e.target.value)} className='form-control'
                                            placeholder='Objet * '
                                        />
                                    </div>

                                </div>
                                <div className="form-group">
                                    <div className='col col-md-6'>
                                        <MultiSelect
                                            options={data_membre}
                                            value={selected}
                                            onChange={handleSelected}
                                            labelledBy="Select"
                                        />
                                    </div>

                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-6'>
                                        <div className="">
                                            <input type="file" onChange={handleFileChange} className='form-control' />
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='col col-lg-12'>
                                        <div className="">
                                            <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="col col-lg-12">
                                        <button type='button' onClick={() => addNotificat()} className='btn btn-info'>Enregistrer</button>
                                        <button type='button' onClick={() => navigate('/notifications')} className='btn btn-danger ' style={{ marginLeft: 10 }}>Annuler</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default AddNotification;