import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import CropperImage from './centers/Cropper';
import { ThemeContext } from '../context/ContextHeader';
import { useContext } from 'react';
function MenuCentre(props) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('top');
  const {
    nbFolderAvisSend,
    nbFolderAvisReceive,
    dossiersEn,
    dossiersCorbeille,
    dossiersArchive,

  } = useContext(ThemeContext);
  return (
    <ul class="sidebar-menu" id="nav-accordion">
      <Dialog header="Veuillez joindre une signature numérique" visible={visible} position={position} style={{ width: '40vw' }} onHide={() => setVisible(false)} draggable={false} resizable={false}>
        <div className='container-fluid'>
          <div className='row'>
            <CropperImage />
          </div>
        </div>
      </Dialog>
      <p class="centered">
        <a href="javascript:;" onClick={() => navigate('/')}><img src="../../../logo.png" class="img-round" width="80" height={80} /></a>
      </p>
      <h5 class="centered">{props.username}</h5>
      <hr style={{ width: "90%" }} />
      <li class="sub-menu">
        <a href="javascript:;" onClick={() => navigate('/resultat')}>
          <i class="fa fa-folder"></i>
          <span>Dossiers reçus&nbsp;&nbsp;<span class="badge bg-theme">{dossiersEn.length>0 ? dossiersEn.length :0}</span></span>
        </a>
      </li>
      <li class="sub-menu" >
        <a href="javascript:;" onClick={() => navigate('/compte_expertise')}>
        <img src="../../../img/diamond_26_whitepx.png" width="15" height="15"  />&nbsp;
          <span>Contre-expertise [{nbFolderAvisReceive}-{nbFolderAvisSend}]&nbsp;&nbsp;<span class="badge bg-theme">{nbFolderAvisReceive+nbFolderAvisSend}</span></span>
        </a>
      </li>
      <li class="sub-menu">
        <a href="javascript:;" onClick={() => navigate('/notifications')}>
          <i class="fa fa-bell-o"></i>
          <span>Notifications&nbsp;&nbsp;<span class="badge bg-theme"></span></span>
        </a>
      </li>
      <li class="sub-menu">
        <a href="javascript:;" onClick={() => navigate('/statistiques')}>
          <i class="fa fa-bar-chart-o"></i>
          <span>Statistiques</span>
        </a>
      </li>
      <li class="sub-menu">
        <a href="javascript:;" onClick={() => navigate('/model_compte_rendu')}>
          <i class="fa fa-clipboard"></i>
          <span>Modèle compte rendu </span>
        </a>
      </li>
      <li class="sub-menu">
        <a href="javascript:;" onClick={() => setVisible(true)}>
          <i class="fa fa-text-width"></i>
          <span>Signature numérique</span>
        </a>
      </li>
      <li class="sub-menu" >
        <a href="javascript:;" onClick={() => navigate('/archives')}>
          <i class="fa fa-cloud-download"></i>
          <span>Archives&nbsp;&nbsp;<span class="badge bg-theme"> {dossiersArchive?.length>0 ? dossiersArchive?.length:0}</span></span>
        </a>
      </li>
      <li class="sub-menu" >
        <a href="https://www.imaios.com/fr/imaios-dicom-viewer" target="_blank" >
          <i class="fa fa-external-link"></i>
          <span>Lecteur Dicom online</span>
        </a>
      </li>
      <li class="sub-menu" >
        <a href="javascript:;" onClick={() => navigate('/corbeille')}>
          <i class="fa fa-bitbucket"></i>
          <span>Corbeille&nbsp;&nbsp;<span class="badge" style={{backgroundColor:'red'}}>{dossiersCorbeille.length>0 ? dossiersCorbeille.length :0}</span></span>
        </a>
      </li>
    </ul>
  );
}

export default MenuCentre;