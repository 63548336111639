import { useEffect, useState } from "react";
import { createContext } from "react";
import { URL_AXIOS } from "../URL/URL";
import axios from "axios";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [text, setText] = useState("");
    const [nbFoldersSend, setNbFoldersSend] = useState(0);
    const [datedebut, setDatedebut] = useState('');
    const [datefin, setDateFin] = useState('');
    const [dossiers, setDossiers] = useState([]);
    const [dossiersEn, setDossiersEn] = useState([]);
    const [disable, setDisable] = useState(false);
    const [disableAvis, setDisableAvis] = useState(false);
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [items, setItems] = useState([]);
    const [nbFoldersRemove, setNbFoldersRemove] = useState(0);
    const [CompteExpertiseData, setCompteExpertiseData] = useState([]);
    const [dataAvisSend, setDataAvisSend] = useState([]);
    const [dataAvisReceive, setDataAvisReceive] = useState([]);
    const [medecinID, setMedecinID] = useState(membre_id);
    const [nbFoldersArchive, setNbFoldersArchive] = useState(0);
    const [nbFolderAvisSend, setNbFolderAvisSend] = useState(0);
    const [nbFolderAvisReceive, setNbFolderAvisReceive] = useState(0);
    const [notifi, setNotifi] = useState([]);
    const [notifiRecep, setNotifiRecep] = useState([]);
    const [notifiLimit, setNotifiLimit] = useState([]);
    const [objet, setObjet] = useState("");
    const [contenu, setContenu] = useState("");
    const [name_recep, setName_recep] = useState("");
    const [societe_recep, setSociete_recep] = useState("");
    const [fichier_notification, setFichier_notification] = useState("");
    const [created_at, setCreated_at] = useState();
    const [name_sender, setName_sender] = useState("");
    const [societe_sender, setSociete_sender] = useState("");
    const [getURLFolder, setGetURLFolder] = useState('');
    const [dataMembre, setDataMembre] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [userID, setUserID] = useState(11);
    const [contentMrc, setContentMrc] = useState("");
    const [statutView, setStatutView] = useState('');
    const [dossiersArchive, setDossiersArchive] = useState([]);
    const [dossiersSousArchive, setDossiersSousArchive] = useState([]);
    const [dossiersCorbeille, setDossiersCorbeille] = useState([]);
    const [dossiersSousCorbeille, setDossiersSousCorbeille] = useState([]);
    const [dataExamenStatistique, setDataExamenStatistique] = useState([]);
    const [periodeContext,setPeriodeContext]=useState("");
    const [examen_total,setExamen_total]=useState(0);
    const BulleCorbeille = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
            if (response.data.status === 200) {
                // setDossiersEn(response.data.dossier_send);
                //setDossiers(response.data.data_dossier_sub);
                setNbFoldersRemove(response.data.data_dossier_num.length)
            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const BulleResultat = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
            if (response.data.status === 200) {
                // setDossiersEn(response.data.dossier_send);
                //setDossiers(response.data.data_dossier_sub);
                setNbFoldersSend(response.data.data_dossier_num.length)


            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const BulleArchive = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
            if (response.data.status === 200) {
                setDossiersArchive(response.data.dossier_send);
            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }


    const liste_dossiers_by_siteEnteteAccordion = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
            if (response.data.status === 200) {
                setDossiersEn(response.data.dossier_send);
                //setDossiers(response.data.data_dossier_sub);
                //setNbFoldersSend(response.data.data_dossier_num.length

            }
        } catch (error) {
           // alert("Erreur " + error.message);
        }
    }
    const liste_dossiers_by_siteEnteteAccordionArchive = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
            if (response.data.status == 200) {
                setDossiersArchive(response.data.dossier_send);
                //setDossiersSousArchive(response.data.data_dossier_sub);
                //setNbFoldersSend(response.data.data_dossier_num.length)

            }
        } catch (error) {

        }
    }
    const liste_dossiers_by_siteEnteteAccordionCorbeille = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
            if (response.data.status === 200) {
                setDossiersCorbeille(response.data.dossier_send);
                //setDossiersSousCorbeille(response.data.data_dossier_sub);
                // setNbFoldersRemove(response.data.data_dossier_num.length)
            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    const BulleAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status === 200) {
                    setNbFolderAvisSend(response.data.data_avis_send.length);

                } else {

                }
            }

        } catch (error) {

        }
    }

    const BulleAvisReceive = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_sollicite/${val}`);
                if (response.data.status === 200) {
                    setNbFolderAvisReceive(response.data.data_avis_sollicite.length);


                } else {

                }
            }

        } catch (error) {

        }
    }


    const liste_notificationRecep = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `CountNumberOFNotification/${user_id}`);
            if (response.data.status === 200) {
                setNotifi(response.data.notificationSend);
                setNotifiLimit(response.data.notificationsLimit);
            }
        } catch (Error) {

        }
    }

    const liste_notificationByUsers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${user_id}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
                // setDataMembre(response.data);
                //setGetURLFolder(response.data.folderUrl)


            }
        } catch (Error) {

        }
    }
    const liste_notificationSendersByUsers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setNotifi(response.data.notifications);
                setDataMembre(response.data);
                // setDataMembre(response.data);
                //setGetURLFolder(response.data.folderUrl)
            }
        } catch (error) {
           // alert("Erreur " + error.message)
        }
    }
    useEffect(() => {

        BulleCorbeille(membre_id);
        BulleResultat(membre_id);
       // BulleArchive(membre_id);
        BulleAvisReceive(membre_id);
        BulleAvisSend(membre_id);
        liste_notificationRecep();
        liste_notificationByUsers();
        liste_notificationSendersByUsers();
        liste_dossiers_by_siteEnteteAccordion(membre_id);
        liste_dossiers_by_siteEnteteAccordionArchive(membre_id);
        liste_dossiers_by_siteEnteteAccordionCorbeille(membre_id);

    }, [])
    return (
        <ThemeContext.Provider value={{
            text, setText,
            datedebut, setDatedebut,
            datefin, setDateFin,
            dossiers, setDossiers,
            dossiersEn, setDossiersEn,
            items, setItems,
            nbFoldersSend, setNbFoldersSend,
            nbFoldersRemove, setNbFoldersRemove,
            disable, setDisable,
            disableAvis, setDisableAvis,
            CompteExpertiseData, setCompteExpertiseData,
            dataAvisSend, setDataAvisSend,
            dataAvisReceive, setDataAvisReceive,
            medecinID, setMedecinID,
            nbFoldersArchive, setNbFoldersArchive,
            nbFolderAvisSend, setNbFolderAvisSend,
            nbFolderAvisReceive, setNbFolderAvisReceive,
            notifi, setNotifi,
            notifiLimit, setNotifiLimit,
            objet, setObjet,
            contenu, setContenu,
            name_recep, setName_recep,
            societe_recep, setSociete_recep,
            fichier_notification, setFichier_notification,
            created_at, setCreated_at,
            name_sender, setName_sender,
            societe_sender, setSociete_sender,
            getURLFolder, setGetURLFolder,
            dataMembre, setDataMembre,
            notifications, setNotifications,
            notifiRecep, setNotifiRecep,
            userID, setUserID,
            disableRefresh, setDisableRefresh,
            contentMrc, setContentMrc,
            statutView, setStatutView,
            dossiersArchive, setDossiersArchive,
            dossiersSousArchive, setDossiersSousArchive,
            dossiersCorbeille, setDossiersCorbeille,
            dossiersSousCorbeille, setDossiersSousCorbeille,
            dataExamenStatistique, setDataExamenStatistique,
            periodeContext,setPeriodeContext,
            examen_total,setExamen_total

        }}>
            {children}
        </ThemeContext.Provider>
    )
}