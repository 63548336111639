import React, { useState } from 'react';
import MenuCentre from './Menu';

function AsideBar(props) {
  const [stat,setStat]=useState("marvin");
    return (
        <aside>
            <div id="sidebar" class="nav-collapse ">
             <MenuCentre username={props.username}/>
            </div>
        </aside>
    );
}

export default AsideBar;