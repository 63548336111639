import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import axios from 'axios';
import { Checkbox } from 'primereact/checkbox';
import { Link, useNavigate } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { BiEdit } from "react-icons/bi";
function CompteExpertise() {
    const {
        items, setItems,
        disable, setDisable,
        disableAvis, setDisableAvis,
        dataAvisSend, setDataAvisSend,
        dataAvisReceive, setDataAvisReceive,
        statutView, setStatutView,
        nbFolderAvisSend, setNbFolderAvisSend,
        nbFolderAvisReceive, setNbFolderAvisReceive,
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [ingredients, setIngredients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 25;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = dataAvisReceive?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(dataAvisReceive?.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [checked, setChecked] = useState(false);

    const [currentPageAvis, setCurrentPageAvis] = useState(1);
    const recordsPerPageAvis = 25;
    const firstIndexAvis = (currentPage - 1) * recordsPerPage;
    const lastIndexAvis = firstIndex + recordsPerPage;
    const recordsAvis = dataAvisSend?.slice(firstIndex, lastIndex);
    const npageAvis = Math.ceil(dataAvisSend?.length / recordsPerPage);
    const numbersAvis = [...Array(npage + 1).keys()].slice(1);

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPageAvis = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPageAvis = (id) => {
        setCurrentPage(id)
    }
    const prevPageAvis = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const handleClickToDetailComponent = (id, avis_id) => {
        setStatutView("CE_TO_DD");
        navigate(`/detail_dossier_patient_avis/${id}/${avis_id}`, { state: { hideParams: true } });
    };
    const ListeAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status == 200) {
                    setDataAvisSend(response.data.data_avis_send);
                    setNbFolderAvisSend(response.data.data_avis_send.length)

                } else {

                }
            }

        } catch (error) {

        }
    }

    const ListeAvisReceive = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_sollicite/${val}`);
                if (response.data.status == 200) {
                    setDataAvisReceive(response.data.data_avis_sollicite);
                    setNbFolderAvisReceive(response.data.data_avis_sollicite.length > 0 ? response.data.data_avis_sollicite.length : 0)

                } else {

                }
            }

        } catch (error) {

        }
    }
    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';

        link.href = value;

        link.click();
    };

    const handleOpen = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = value;
        link.target = "_blank";

        link.click();
    };
    const onIngredientsChange2 = (e, statut) => {
        let _ingredients = [...ingredients];
        if (e.checked)
            _ingredients.push(e.value);

        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
        setItems(_ingredients);
        if (_ingredients.length > 0) {

            if (statut === "1" || statut === "2") {
                setDisable(true)
                setDisableAvis(false);
            } else if (statut === "0") {
                setDisable(false);
                setDisableAvis(false)
            }
        } else {
            setDisable(true)
            setDisableAvis(true)
        }
        e.stopPropagation();
    }
    const onIngredientsChange = (e, statut) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);

        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
        setItems(_ingredients);
        if (_ingredients.length > 0) {

            if (statut === "1") {
                setDisable(true)

            } else {
                setDisable(false)
            }
            setDisableAvis(false)

        } else {
            setDisable(true)
            setDisableAvis(true)
        }
        e.stopPropagation();
    }
    const Title = (societe) => {
        return (
            <div style={{ display: 'flex', alignItems: 'start' }}>
                <h5 style={{ color: '#18d26e' }}><i className='fa fa-user '>&nbsp;</i>{societe}</h5>

            </div>
        )
    }
    const handleApercuAvis = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';
        link.href = `/apercu_avis/${value}`;
        link.target = "_blank";
        link.click();
        e.stopPropagation();
    };
    useEffect(() => {
        setDisableAvis(true);
        ListeAvisSend(membre_id);
        ListeAvisReceive(membre_id);
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='container-fluid'>
                        <div className='row' style={{ marginTop: 10 }}>
                            <TabView>
                                <TabPanel header="CONTRE EXPERTISE (SOLLICITE)" headerStyle={{ fontSize: 12 }} style={{ fontSize: 12, color: '#000' }}>
                                    {dataAvisReceive.length > 0 && <div class="row " style={{ marginTop: 10 }}>

                                        <table class="table table-bordered table-striped table-condensed">
                                            <thead>
                                                <tr style={{ backgroundColor: 'white' }}>
                                                    <th style={{ color: 'red' }}><center></center></th>
                                                    <th style={{ color: 'red' }}>Envoyé par</th>
                                                    <th style={{ color: 'red' }}>Patient(s)</th>
                                                    <th style={{ color: 'red', width: '20%' }}>Examen(s)</th>
                                                    <th style={{ color: 'red' }}>Catégorie(s)</th>
                                                    <th style={{ color: 'red' }}>Date</th>
                                                    <th style={{ color: 'red' }} colSpan={3}></th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records
                                                    .map((dossierRecu, index) => (
                                                        <tr key={dossierRecu.dossier_id}>
                                                            <td><center>
                                                                {dossierRecu.etat == 1 &&
                                                                    <img
                                                                        src="../../../assets/img/urgent.png"
                                                                        width="50"
                                                                        style={{ marginTop: 5 }}
                                                                    />}
                                                            </center></td>
                                                            <td>{dossierRecu.nom_send + " " + dossierRecu.prenom_send}</td>
                                                            <td>{dossierRecu.nom_patient}</td>
                                                            <td>
                                                                <div style={{ display: 'flex' }}>

                                                                    {dossierRecu.nom_fichier !== null && <h6><Link style={{ color: 'green' }}
                                                                        to={'/resultat'}
                                                                        onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier)}
                                                                    >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </h6>}{dossierRecu.nom_fichier_2 !== null && <h6>&nbsp;<Link style={{ color: 'green' }}
                                                                        to={'/resultat'}
                                                                        onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier_2)}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></h6>}
                                                                    {dossierRecu.bulletin_examen !== null && <h6><Link to='/resultat' style={{ position: 'absolute', color: 'green' }} onClick={(e) => handleOpen(e, URL_FOLDER_API + dossierRecu.chemin_bulletin)}>Bulletin</Link></h6>}
                                                                </div>
                                                            </td>
                                                            <td>{dossierRecu.categorie_fichier}</td>
                                                            <td>{dossierRecu.created_at}</td>
                                                            <td><center>
                                                                <Checkbox inputId={`dossier_${dossierRecu.avis_id}`} name={dossierRecu.avis_id} value={dossierRecu.avis_id} onChange={(e) => onIngredientsChange(e, dossierRecu.statut)} checked={ingredients.includes(dossierRecu.avis_id)} />
                                                            </center></td>
                                                            <td>
                                                                <i className='fa fa-hand-o-up'
                                                                    onClick={() => navigate(`/member_detail_folder/${dossierRecu.dossier_id}`)}
                                                                    style={{ color: 'red', cursor: 'pointer' }}>
                                                                </i>
                                                            </td>
                                                            <td>
                                                                <BiEdit style={{ color: 'green', cursor: 'pointer', fontSize: 15 }} onClick={() => handleClickToDetailComponent(dossierRecu.dossier_id, dossierRecu.avis_id)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>

                                        </table>

                                        <nav>
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a href='#' className='page-link'
                                                        onClick={prevPage}
                                                    >Prev</a>
                                                </li>
                                                {
                                                    numbers.map((n, i) => (
                                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                            <a href='#' className='page-link'
                                                                onClick={() => changeCPage(n)}
                                                            >{n}</a>
                                                        </li>
                                                    ))
                                                }
                                                <li className="page-item">
                                                    <a href='#' className='page-link'
                                                        onClick={nextPage}
                                                    >Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>}
                                    {dataAvisReceive.length <= 0 && <div style={{ display: 'flex' }}><img src='../../img/folder_empty.png' /> <h3 style={{ color: 'red', marginTop: '110px' }}>Aucun dossier trouvé</h3></div>}

                                </TabPanel>
                                <TabPanel header="CONTRE EXPERTISE (AVIS)" headerStyle={{ fontSize: 12 }} style={{ fontSize: 12, color: '#000' }}>
                                    {dataAvisSend.length > 0 && <div class="row " style={{ marginTop: 10 }}>

                                        <table class="table table-bordered table-striped table-condensed">
                                            <thead>
                                                <tr style={{ backgroundColor: 'white' }}>
                                                    <th style={{ color: 'red' }}><center></center></th>
                                                    <th style={{ color: 'red' }}>Envoyé à</th>
                                                    <th style={{ color: 'red' }}>Patient(s)</th>
                                                    <th style={{ color: 'red', width: '20%' }}>Examen(s)</th>
                                                    <th style={{ color: 'red' }}>Catégorie(s)</th>
                                                    <th style={{ color: 'red' }}>Date</th>
                                                    <th colSpan={3} style={{ color: 'red' }}></th>
                                           

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recordsAvis
                                                    .map((dossierRecu, index) => (
                                                        <tr key={dossierRecu.id}>
                                                            <td><center>
                                                                {dossierRecu.etat == 1 &&
                                                                    <img
                                                                        src="../../../assets/img/urgent.png"
                                                                        width="50"
                                                                        style={{ marginTop: 5 }}
                                                                    />}
                                                            </center></td>
                                                            <td>{dossierRecu.nom_recep + " " + dossierRecu.prenom_recep}</td>
                                                            <td>{dossierRecu.nom_patient}</td>
                                                            <td>
                                                                <div style={{ display: 'flex' }}>

                                                                    {dossierRecu.nom_fichier !== null && <h6><Link style={{ color: 'green' }}
                                                                        to={'/resultat'}
                                                                        onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier)}
                                                                    >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </h6>}{dossierRecu.nom_fichier_2 !== null && <h6>&nbsp;<Link style={{ color: 'green' }}
                                                                        to={'/resultat'}
                                                                        onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier_2)}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></h6>}
                                                                    {dossierRecu.bulletin_examen !== null && <h6><Link to='/resultat' style={{ position: 'absolute', color: 'green' }} onClick={(e) => handleOpen(e, URL_FOLDER_API + dossierRecu.chemin_bulletin)}>Bulletin</Link></h6>}
                                                                </div>
                                                            </td>
                                                            <td>{dossierRecu.categorie_fichier}</td>
                                                            <td>{dossierRecu.created_at}</td>
                                                            <td><center>
                                                                <Checkbox inputId={`dossier_${dossierRecu.avis_id}`} name={dossierRecu.avis_id} value={dossierRecu.avis_id} onChange={(e) => onIngredientsChange(e, dossierRecu.statut)} checked={ingredients.includes(dossierRecu.avis_id)} />
                                                            </center></td>
                                                            <td>
                                                                <i className='fa fa-hand-o-up'
                                                                    onClick={() => navigate(`/member_detail_folder/${dossierRecu.dossier_id}`)}
                                                                    style={{ color: 'red', cursor: 'pointer' }}>
                                                                </i>
                                                            </td>
                                                            <td>
                                                                <BiEdit style={{ color: 'green', cursor: 'pointer', fontSize: 15 }} onClick={() => handleClickToDetailComponent(dossierRecu.dossier_id, dossierRecu.avis_id)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>

                                        </table>

                                        <nav>
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a href='#' className='page-link'
                                                        onClick={prevPageAvis}
                                                    >Prev</a>
                                                </li>
                                                {
                                                    numbersAvis.map((n, i) => (
                                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                            <a href='#' className='page-link'
                                                                onClick={() => changeCPageAvis(n)}
                                                            >{n}</a>
                                                        </li>
                                                    ))
                                                }
                                                <li className="page-item">
                                                    <a href='#' className='page-link'
                                                        onClick={nextPageAvis}
                                                    >Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>}
                                    {dataAvisSend.length <= 0 && <div style={{ display: 'flex' }}><img src='../../img/folder_empty.png' /> <h3 style={{ color: 'red', marginTop: '110px' }}>Aucun dossier trouvé</h3></div>}

                                </TabPanel>

                            </TabView>
                        </div>


                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default CompteExpertise;