import React, { useContext, useEffect } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Card } from 'primereact/card';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Avatar } from 'primereact/avatar';
import { Panel } from 'primereact/panel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function LectureNotification() {
    const {
        objet, setObjet,
        contenu, setContenu,
        name_recep, setName_recep,
        societe_recep, setSociete_recep,
        fichier_notification, setFichier_notification,
        created_at, setCreated_at,
        name_sender, setName_sender,
        societe_sender, setSociete_sender,
        notifiRecep, setNotifiRecep,
        userID, setUserID
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [selectedFile, setSelectedFile] = useState(null);
    const liste_notification = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${userID}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
            }
        } catch (Error) {

        }
    }
    const lireNotification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `updateStatus_notification/${id}`);
            if (response.data.status == 200) {
                liste_notification();
                setObjet(response.data.notification.objet)
                setContenu(response.data.notification.contenu)
                setName_recep(response.data.notification.name_sender)
                setName_sender(response.data.notification.name_sender)
                setCreated_at(response.data.notification.created_at)
                setFichier_notification(response.data.notification.fichier_notification)
                setSociete_recep(response.data.notification.societe_recep);
               
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const Title = (val) => {
        return (
            <div className='row'>
                <div className='col col-md-6'>
                    <div style={{ display: 'flex' }}>
                        <Avatar icon="pi pi-user" size="xlarge" shape="circle"
                            style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                        />
                        <h5 style={{ marginLeft: 10 }}>{val.name_sender}</h5>
                    </div>

                </div>
                <div className='col col-md-6'>
                    <h5 style={{ marginLeft: 10, float: 'right' }}>{val.societe_sender}</h5>
                </div>
            </div>
        )
    }

    const FooterPage = (date, image, status) => {
        return (
            <div style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', color: '#2196F3' }}>{date}</p>
                <a href='#'>{image && <i className='fa fa-paperclip' style={{ fontSize: 20, marginLeft: 20 }}></i>}</a>
                <p style={{ marginLeft: 20 }}>{status == "0" ? "Non lu" : 'lu'}</p>
            </div>
        )
    }
    const TitleLecture = (name, societe) => {
        return (
            <div className='row'>
                <div className='col col-md-6'>
                    <div style={{ display: 'flex' }}>
                        <Avatar icon="pi pi-user" size="xlarge" shape="circle"
                            style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                        />
                        <h5 style={{ marginLeft: 10 }}>{name}</h5>
                    </div>

                </div>
                <div className='col col-md-6'>
                    <h5 style={{ marginLeft: 10, float: 'right' }}>{societe}</h5>
                </div>
            </div>
        )
    }
    useEffect(() => {
        //liste_notification();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ marginTop: 10 }}>
                        <div className='col col-md-6'>
                            <h5>Tous les messages</h5>
                            <ScrollPanel style={{ width: '100%', height: '600px' }} className="custombar1">
                                {notifiRecep.map((noti) => (

                                    <Card title={Title(noti)} onClick={() => lireNotification(noti.id_notification)} style={{ marginTop: 10, cursor: 'pointer' }} footer={FooterPage(noti.created_at, noti.fichier_notification, noti.status_notification)}>
                                        <h6>{noti.objet}</h6>

                                    </Card>


                                ))}
                                {notifiRecep.length > 0 ? " " : "Aucune notifications"}
                            </ScrollPanel>
                        </div>
                        <div className='col col-md-6'>
                            <ScrollPanel style={{ width: '100%', height: '600px' }} className="custombar1">
                                {objet && <Card title={TitleLecture(name_recep, societe_recep)} style={{ marginTop: 40 }}
                                    footer={FooterPage(created_at, fichier_notification)}
                                >
                                    <h6>{objet}</h6>
                                    <p className="m-0" >
                                        {contenu}

                                    </p>
                                </Card>}
                            </ScrollPanel>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default LectureNotification;