import React, { useContext, useEffect, useState, useRef } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2.js'
function Notifications() {
    const {
        text, setText,
        datedebut, dossiersEn,
        setDossiersEn, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis,
        medecinID, setMedecinID,
        notifi, setNotifi,
        getURLFolder, setGetURLFolder,
        dataMembre, setDataMembre,
        notifications, setNotifications,
        userID, setUserID
    } = useContext(ThemeContext);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const navigate = useNavigate();
    const recordsPerPage = 4;
    //const [notifi, setNotifi] = useState([]);
    const [notifidata, setNotifiData] = useState([]);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = notifications.slice(firstIndex, lastIndex);
    const npage = Math.ceil(notifications.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [nameMembres, setNameMembres] = useState([]);
    //const [dataMembre, setDataMembre] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const dateStart_two = new Date(dateStart).toDateString();
    const dateEnd_two = new Date(dateEnd).toDateString();
    const [cancel, setCancel] = useState(true);
    const [statut_search, setStatut_search] = useState(false);
    //const [getURLFolder, setGetURLFolder] = useState('');

    const confirm2 = (id) => {

        Swal.fire({
            title: "Voulez-vous supprimer cette notification ?",
            showCancelButton: true,
            confirmButtonText: "Valider",
            icon: 'question'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                delete_notification(id);
                liste_notification();
                Swal.fire("La notification a été supprimée avec succès !", "", "success");
            } else if (result.isCanceled) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
        /* confirmDialog({
             message: 'Voulez-vous supprimer cette notification ?',
             header: 'Confirmation Suppression ',
             icon: 'pi pi-info-circle',
             acceptClassName: 'p-button-danger',
             accept: () => {
                 delete_notification(id);
                 liste_notification();
             },
             reject: () => {
 
             }
         });*/
    };

    const delete_notification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_notifications/${id}`);
            if (response.data.status === 200) {
                //Toast.current.show({ severity: 'info', summary: 'Confirmed', detail: response.data.message, life: 3000 });


            }
        } catch (Error) {

        }

    }
    const toast = useRef(null);
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const liste_notification = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)
            }
        } catch (Error) {

        }
    }

    const fetchMembres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                const mappedMembres = response.data.users_sites.map((item) => ({
                    label: item.name,
                    value: item.user_id
                }));
                setMembres(mappedMembres);
            }
        } catch (error) {
            console.error("Error fetching membres:", error);
        }
    };
    const handleQueryText = async () => {
        try {
            //const query_name = e.target.value;
            //setQuery(query_name);

            setCurrentPage(1);

            if (query.length > 0) {


                const response = await axios.post(URL_AXIOS + 'recherche_notification_by_objet_or_site', {
                    keyValue: query.toUpperCase()
                });
                if (response.data.status === 200) {
                    setNotifi(response.data.notifications);
                    setStatut_search(true)
                } else {

                }
            } else {
                liste_notification();
            }
        } catch (error) {
            //liste_notification();
        }
    };



    const handleDownload = (e, si) => {
        e.preventDefault();
        //const getURL = getURLFolder;
        const downloadLink = document.createElement('a');
        downloadLink.href = getURLFolder + "/" + si.fichier_notification;
        downloadLink.download = si.fichier_notification;
        downloadLink.target = "_blank";
        downloadLink.click();
    };

    useEffect(() => {
        liste_notification();
        fetchMembres();
        setDisableAvis(true);
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="container">
                <ConfirmDialog />
                <Toast ref={toast} />


                <section id="main-content">
                    <section class="wrapper">
                        <div className='row ' style={{ padding: 10, marginTop: 20 }}>

                            <div className="col col-md-1">
                                <Button className='btn btn-info' label='Nouvelles notifications'
                                    onClick={() => navigate('/addNotifications')} style={{ height: 35 }}
                                />
                            </div>

                        </div>
                        <div class="row " style={{ padding: 10 }}>
                            <i class="fa-solid fa-file-pdf"></i>
                            <table class="table table-bordered table-striped table-condensed">
                                <thead>
                                    <tr style={{backgroundColor:'white'}}>
                                        <th style={{color:'red'}}><center>#</center></th>
                                        <th style={{color:'red'}}>OBJET</th>
                                        <th style={{color:'red'}}>MESSAGE</th>
                                        <th style={{color:'red'}}>ENVOYE PAR</th>
                                        <th style={{color:'red'}}>RECU PAR</th>
                                        <th style={{color:'red'}}>DATE</th>
                                        <th colSpan={3} style={{ width: 30,color:'red' }}><center>ACTIONS</center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((si, index) => (
                                        <tr key={index}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.objet}</td>
                                            <td>{si.contenu}</td>
                                            <td>{si.name_sender}</td>
                                            <td>
                                                {dataMembre.membres[si.id_notification].length === membres.length ? "Tous les utilisateurs" : dataMembre.membres[si.id_notification]?.map((data, index) => (
                                                    <div key={index}>{data.name_recep + ","}</div>
                                                ))}
                                            </td>
                                            <td>{si.created_at}</td>
                                            <td style={{ width: 30 }}><center><button type="button" className='btn btn-warning' onClick={() => navigate(`/editionNotification/${si.id_notification}`)} style={{ backgroundColor: "orange" }} >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td style={{ width: 30 }}><center>
                                                <button type='button' className="btn btn-danger" style={{ backgroundColor: "red" }} onClick={() => confirm2(si.id_notification)} >
                                                    <i className='fa fa-trash'></i>
                                                </button>

                                            </center></td>
                                            <td style={{ width: 30 }}>
                                                <center>

                                                    <button type='button' className='btn btn-default' disabled={si.fichier_notification === "" ? true : false}
                                                        style={{ backgroundColor: "grey" }}
                                                        onClick={(e) => handleDownload(e, si)}
                                                    >
                                                        <i className='fa fa-download' style={{color:'white'}}></i>
                                                    </button>
                                                </center>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <nav>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={prevPage}
                                        >Prev</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href='#' className='page-link'
                                                    onClick={() => changeCPage(n)}
                                                >{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={nextPage}
                                        >Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </section>
                </section >
                <Footer />
            </section >

        </div>
    );
}

export default Notifications;