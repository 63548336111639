import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_AXIOS } from '../URL/URL';


function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [motpasse, setMotpasse] = useState("");

    const Login = async () => {

        try {
            const data = {
                email: email,
                motpasse: motpasse
            }
            const response = await axios.post(URL_AXIOS + 'Login', data);
            if (response.data.status === 200) {

                if (response.data.Login) {

                    if (response.data.Login.role === "M") {
                        navigate('/resultat')
                        sessionStorage.setItem("nom", response.data.Login.name);
                        sessionStorage.setItem("user_id", response.data.Login.id);
                        sessionStorage.setItem("site_id", response.data.Login.site_id);
                        sessionStorage.setItem("societe", response.data.Login.societe);
                        sessionStorage.setItem("type", response.data.Login.type);
                        sessionStorage.setItem("membre_id", response.data.membre.id);
                        sessionStorage.setItem("exp", response.data.membre.exp);
                        sessionStorage.setItem("etat", response.data.membre.etat);
                        //alert("Datedebut "+response.data.marvin)
                    } else {
                        // alert("Incorrect")
                    }

                } else {
                    alert("Mot de passe ou nom d'utilisateur incorrect")
                }
            } else {
                alert("Mot de passe ou nom d'utilisateur incorrect")
            }
        } catch (error) {
            alert("Incorrect" + error.message)
        }
    }
    return (
        <div id="login-page" style={{ backgroundImage: 'url(../../img/login-bg2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
            <div class="container">
                <form class="form-login">
                    <h2 class="form-login-heading" style={{ fontWeight: 'bold' }}>TER<span style={{ color: "#d9534f" }}>AT</span>EX</h2>
                    <div class="login-wrap">
                        <input type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            class="form-control"
                            placeholder="Saisissez votre mail"
                            autofocus />
                        <br />
                        <input
                            value={motpasse}
                            onChange={(e) => setMotpasse(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="Saisissez votre mot de passe" />
                        <div class="checkbox" style={{ display: "flex", justifyContent: "space-between" }}>
                            <input type="checkbox" value="remember-me" style={{ marginLeft: "0%" }} />
                            <p style={{ marginLeft: "5%" }}>Se souvenir de moi</p>
                            <span class="pull-right">
                                <a href="https://e-metech.com/forgot-password" style={{ color: "#337ac7" }}> Forgot Password?</a>
                            </span>
                        </div>
                        <button class="btn btn-theme btn-block"
                            style={{ backgroundColor: "#223a66" }}
                            type="button"
                            onClick={() => Login()}
                        ><i class="fa fa-lock"></i>&nbsp;&nbsp;CONNEXION</button>
                        <hr />
                        <a className='btn btn-danger form-control' style={{ color: "white" }} href="https://e-metech.com/"><i className='fa fa-home'></i>Accueil</a>
                        <div class="registration" style={{ marginTop: 20 }}>
                            Vous avez pas encore de compte ?<br />
                            <a href="https://e-metech.com/register" style={{ color: "#337ac7" }}>
                                Créer un compte
                            </a>
                        </div>
                    </div>

                    <div aria-hidden="true" aria-labelledby="myModalLabel" role="dialog" tabindex="-1" id="myModal" class="modal fade">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                    <h4 class="modal-title">Forgot Password ?</h4>
                                </div>
                                <div class="modal-body">
                                    <p>Enter your e-mail address below to reset your password.</p>
                                    <input type="text" name="email" placeholder="Email" autocomplete="off" class="form-control placeholder-no-fix" />
                                </div>
                                <div class="modal-footer">
                                    <button data-dismiss="modal" class="btn btn-default" type="button">Cancel</button>
                                    <button class="btn btn-theme" type="button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default Login;